@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-journal-semd-details-common-tab {
  @include flex(row, center, flex-start);

  padding: 16px;

  .card {
    &:first-of-type {
      margin-right: 16px;
    }
  }
}
