@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-long-data-modal {
  .ant-drawer-body {
    padding: 0;
  }

  .long-data-modal-content {
    @include flex(row, flex-start, stretch);

    height: 100%;

    .long-data-modal-content-textarea {
      overflow: scroll;
      width: 100%;
      border: 1px solid #ABC0C2;
      margin: 10px;
      border-radius: 4px;
      font-family: "Courier New", Courier, monospace;
      font-size: 12px;
      line-height: 18px;
      outline: none !important;
      overflow-wrap: normal;
      overflow-x: scroll;
      resize: none;
      white-space: pre;

      &:hover {
        border: 1px solid #ABC0C2;
      }

      &:focus {
        border: 1px solid #ABC0C2;
      }

      &:focus-visible {
        border: 1px solid #ABC0C2;
      }
    }
  }
}
