@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-extra-route-drawer {
  max-height: 100%;

  .ant-drawer-body {
    @include flex(column, flex-start, stretch);

    padding: 4px 32px;
    background: url("~src/assets/icons/dot.svg");
    background-color: $color-background-main;
  }
}
