@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-notification {
  @include flex(row, flex-end, flex-start);

  position: relative;
  bottom: 25px;
  width: 500px;
  min-height: 46px;
  padding: 8px 8px 16px;
  margin-top: 10px;
  animation: notification-colapse 0.2s linear;
  border-radius: 8px;
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.24);
  color: $color-blue-gray-80;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  .notification-row {
    @include flex(row, left, flex-start);

    margin-top: 8px;
  }

  .notification-info-svg {
    width: 16px;
    margin-right: 8px;
  }

  .notification-content {
    width: 428px;
    word-break: break-word;
  }

  .notification-title {
    margin-top: 2px;
    margin-bottom: 4px;
    color: $color-blue-gray-40;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  &.success {
    background-color: $color-notification-success;
  }

  &.error {
    background-color: $color-notification-error;
  }

  &.warning {
    background-color: $color-notification-warning;
  }

  &.info {
    background-color: $color-notification-info;
  }
}

@keyframes notification-colapse {
  from {
    bottom: 0;
  }

  to {
    bottom: 25px;
  }
}
