@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-tab-medrecords {
  @include flex(column, flex-start, stretch);

  height: 100%;
  padding: 16px;

  .root-data-grid {
    border-left: 1px solid #D5DEDF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .root-linear-progress {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .services-icon {
    @include flex(row, center, center);

    width: 100%;
    height: 100%;

    .root-icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
    }
  }

  .cell-column {
    @include flex(column, space-around, center);

    width: calc(100% + 2px);
    height: 100%;
    flex-grow: 1;
    margin: -4px -8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;

    .cell-column-text {
      overflow: hidden;
      width: inherit;
      padding: 0 8px;
      border-bottom: 1px solid $color-gray-80;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        border-bottom: 0 solid $color-gray-80;
      }
    }
  }

  .root-tab-medrecords-controls {
    @include flex(row, flex-start, center);

    padding: 16px;
    background-color: white;
    color: $color-blue-gray-40;
    font-size: 12px;
    font-weight: 500;
  }

  .root-tab-medrecords-icon-setting {
    position: absolute;
    z-index: 1;
    top: -35px;
    right: 30px;
  }
}
