@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-admin-roles-page {
  @include flex(column, flex-start, stretch);

  flex-grow: 1;

  .copy-role-modal-content-input-field {
    width: 90%;
    margin: 20px auto;
  }

  .admin-roles-content {
    position: relative;

    .admin-roles-content-loader-overlay {
      @include flex(row, center, center);

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .admin-roles-content-view {
      @include flex(column, flex-start, stretch);

      &.admin-roles-content-view-loading {
        overflow: hidden;
      }

      .admin-roles-actions {
        @include flex(row, flex-start, center);

        padding: 16px;
        background-color: white;

        & > * {
          margin-right: 8px;
        }
      }

      .admin-roles-panels {
        @include flex(row, flex-start, stretch);

        .admin-roles-roles-list {
          max-height: calc(100vh - 155px);
          background-color: white;
          overflow-y: auto;

          table {
            width: 100%;
            border-collapse: collapse;
            table-layout: fixed;

            tr {
              color: $color-black-light;
              cursor: pointer;

              &:nth-child(even) {
                background: $color-gray-120;
              }

              &.selected {
                background-color: #E3F1F4 !important;
              }

              th {
                padding: 8px;
                border: 1px solid #D5DEDF;
                background: #FAFBFA;
                color: $color-blue-gray-60;
                font-size: 12px;
                font-weight: 500;
                text-align: left;

                &:first-child {
                  border-left: 0;
                }

                div:first-child {
                  margin-bottom: 10px;
                }
              }
              th:nth-child(1) { width: 25%; }
              th:nth-child(2) { width: 30%; }
              th:nth-child(3) { width: 30%; }
              th:nth-child(4) { width: 10%; }

              td {
                padding: 8px;
                border: 1px solid #D5DEDF;
                color: #19282B;
                font-size: 13px;
                font-weight: normal;

                .people-employees {
                  @include flex(row, flex-start, center);

                  svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                  }

                  span {
                    color: $color-green-80;
                    text-decoration-line: underline;

                    &:hover {
                      text-decoration-line: none;
                    }
                  }
                }

                &:first-child {
                  border-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
