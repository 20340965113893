@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-dict-passport-tab {
  position: relative;
  padding: 16px 19px;

  .dict-passport-tab-content-view {
    @include flex(column, flex-start, stretch);

    max-width: 1000px;
  }
}
