@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-attributes-drawer {
  .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .ant-drawer-header {
      border-bottom: 1px solid #DFE3E6;

      .ant-drawer-header-title {
        flex-direction: row-reverse;
      }
    }

    .ant-drawer-body {
      overflow: hidden;
      max-height: 100%;
      padding: 0;
    }

    .ant-drawer-footer {
      border-top: 1px solid #DFE3E6;
    }
  }
}

.root-attributes {
  display: flex;
  min-width: 1000px;
  height: 100%;
  flex-direction: row;
  align-items: stretch;
  background-color: $color-gray-120;

  .attributes-characteristics {
    width: 475px;
    border-right: 1px solid #DFE3E6;
    background-color: $color-white;

    .characteristics-title {
      border-bottom: 1px solid #DFE3E6;

      & > span {
        color: #42474D;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .characteristics-inputs {
      height: calc(100% - 52px);
      max-height: calc(100% - 52px);
      overflow-y: auto;
    }
  }

  .attributes-conditions {
    height: 100%;
    max-height: 100%;
    flex: 1;
    padding: 16px;
    overflow-y: auto;

    .attributes-add-disjunct {
      position: relative;
      border: 1px dashed $color-green-80;
      border-radius: 2px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgba($color-green-100, 0.05);
      }

      &:active {
        background-color: rgba($color-green-100, 0.1);
      }

      .root-badge {
        position: absolute;
        left: 16px;
      }

      & > span {
        color: $color-green-80;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-decoration-line: underline;
        user-select: none;
      }
    }
  }
}

.root-disjunct {
  width: 100%;
  border: 1px solid #DFE3E6;
  background-color: $color-white;
  border-radius: 8px;

  .disjunct-header {
    border-bottom: 1px solid #DFE3E6;

    & > span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      user-select: none;
    }

    .disjunct-trash-icon {
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $color-gray-120;
      }

      &:active {
        background-color: $color-blue-gray-60;
      }

      .root-icon {
        path {
          fill: $color-blue-gray-60;
        }
      }
    }
  }

  .disjunct-content {
    .attribute-tree-selector {
      width: calc(100% - 80px);
    }

    .conjunct-list {
      position: relative;
      width: calc(100% - 80px);

      .root-conjunct:not(:last-child) {
        margin-bottom: 16px;
      }

      &.display-line::before {
        position: absolute;
        left: -32px;
        width: 8px;
        height: 100%;
        border-top: 2px solid $color-green-80;
        border-bottom: 2px solid $color-green-80;
        border-left: 2px solid $color-green-80;
        margin-bottom: 0;
        content: "";
      }

      .root-badge {
        position: absolute;
        top: calc(50% - 12px);
        left: -42px;
      }
    }

    .disjunct-add-typical-block {
      width: calc(100% - 100px);
      padding: 2px 0;
      border-radius: 2px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $color-gray-120;
      }

      &:active {
        background-color: $color-gray-100;
      }

      & > span {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        user-select: none;
      }
    }
  }
}

.root-conjunct {
  position: relative;

  .conjunct-attribute {
    flex: 1 0 60%;
  }

  .conjunct-value {
    @include flex(row, flex-start, center);

    min-width: 0;
    flex-basis: 35%;
    flex-grow: 0;
  }

  .conjunct-delete-icon {
    position: absolute;
    right: -41px;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $color-gray-120;
    }

    &:active {
      background-color: $color-gray-100;
    }
  }
}

.root-operation-selector {
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &:hover {
    border-color: $color-blue-gray-40;
  }

  &:active {
    background-color: $color-gray-80;
  }

  &.disabled {
    background-color: inherit;
    transition: none;

    &:hover {
      border: 0;
    }

    &:active {
      background-color: inherit;
    }
  }
}

.attribute-tree-selector-dropdown {
  // Делаем "погашенные" опции менее блеклыми
  .ant-select-tree-treenode-disabled {
    .ant-select-tree-title {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
