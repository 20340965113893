@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-app-header {
  @include flex(row, space-between, center);

  position: absolute;
  z-index: 99;
  top: 0;
  width: calc(100% - 48px);
  height: 48px;
  margin-left: 48px;
  background-color: $color-white;
  color: $color-blue-gray-40;

  .app-header-left {
    @include flex(row, flex-start, center);

    .app-header-logo-container {
      @include flex(row, flex-start, center);
    }

    .app-header-breadcrumbs {
      @include flex(row, flex-start, center);

      max-width: calc(100vw - 250px);
      margin-left: 16px;
      color: $color-blue-gray-40;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;

      .app-nav-breadcrumb {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .app-header-right {
    @include flex(row, flex-end, center);

    position: relative;
    padding-right: 15px;
    color: $color-blue-gray-40;

    .app-header-user-icon {
      width: 16px;
      height: 16px;
      margin: 6px;
    }

    .app-header-user {
      @include flex(row, flex-start, center);

      color: $color-blue-gray-40;
      cursor: pointer;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;

      .app-header-user-name::first-letter {
        color: $color-primary;
      }
    }

    .app-header-logout {
      width: 16px;
      height: 16px;
      margin-left: 20px;
    }
  }
}

.app-header-user-role {
  .ant-popover-content {
    border: 1px solid #E3F1F4;
    background-color: white;
    border-radius: 12px;

    @include dropdown-shadow;

    .ant-popover-arrow-content {
      border: 1px solid #E3F1F4;
    }
  }
}
