@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-tab-general-route {
  .wrapper-top {
    padding: 16px 12px;
    border: 1px solid #D5DEDF;
    background-color: white;
    border-radius: 8px;
  }

  .table-header {
    padding: 16px 12px;
    border: 1px solid #D5DEDF;
    border-bottom: 0;
    background-color: white;
    border-radius: 8px 8px 0 0;
  }

  .bottom-buttons {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 12px;
    border-top: 1px solid #D5DEDF;
    background-color: #FFF;
  }

  .grid-icon-area {
    margin-right: auto;
    margin-left: auto;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $color-gray-100;
    }

    &:active {
      background-color: $color-gray-80;
    }
  }
}
