@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-admin-appointment-page {
  min-width: 750px;
  max-height: 94vh;
  overflow-y: clip;

  .appointment-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .appointment-block-left {
    width: 30%;
    max-height: 90vh;
    background-color: white;
  }

  .appointment-block-center {
    width: 22%;
    background-color: white;

    .button-wrapper {
      margin-left: 0 !important;
    }
  }

  .appointment-block-right {
    display: flex;
    width: 48%;
    background-color: white;

    .big-button-wrapper {
      display: flex;
      padding-right: 5px;
      margin-top: 42px;

      div:nth-child(1) {
        border-radius: 5px;
      }
    }

    .button-wrapper {
      @include flex(row, right, flex-start);
    }
  }

  .appointment-action-button {
    margin: 0 8px;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    margin: 16px;

    .appointment-text-input {
      margin-right: 16px !important;
    }
  }

  .appointment-roles-roles-list {
    max-height: calc(100vh - 130px);
    background-color: white;
    overflow-y: scroll;

    .table-left {
      border-left: none;
    }

    table {
      width: 100%;
      border-left: 1px solid #D5DEDF;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;

      thead {
        position: sticky;
        top: 0;
      }

      .row-1 {
        background: #FAFBFA;
      }

      tr {
        color: $color-black-light;
        cursor: pointer;

        &:hover {
          background-color: #F7F7F7;
        }

        &.selected {
          background-color: #E3F1F4;
        }

        th {
          padding: 8px;
          border-top: 1px solid #D5DEDF;
          border-right: 1px solid #D5DEDF;
          border-bottom: 1px solid #D5DEDF;
          background: #FAFBFA;
          color: $color-blue-gray-60;
          font-size: 12px;
          font-weight: 500;
          text-align: left;

          &:first-child {
            border-left: 0;
          }

          div:first-child {
            margin-bottom: 10px;
          }
        }

        th:nth-child(1) {
          width: 32%;
        }

        th:nth-child(2) {
          width: 30%;
        }

        th:nth-child(3) {
          width: 35%;
        }

        td {
          padding: 8px;
          border-right: 1px solid #D5DEDF;
          border-bottom: 1px solid #D5DEDF;
          color: #19282B;
          font-size: 13px;
          font-weight: normal;

          &:first-child {
            border-left: 0;
          }
        }
      }
    }
  }

  .sort-icon {
    width: 23px;
    min-width: 23px;
    height: 23px;
    min-height: 23px;
    padding: 3px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.1s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .th-content-wrapper {
    @include flex(row, space-between, flex-start);

    span:first-child {
      margin-right: 10px;
    }
  }
}
