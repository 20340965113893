@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.add-user-role-section {
  .add-user-role-section-content {
    @include flex(column, flex-start, stretch);

    padding: 16px;
    padding-top: 0;

    &-input {
      @include flex(column, flex-start, stretch);

      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        color: $color-blue-gray-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }
    }
  }

  .add-user-role-section-error {
    padding: 0 16px 8px;
    color: $color-red;
    font-size: 12px;
  }

  .add-user-role-section-footer {
    @include flex(row, flex-end, center);

    padding: 16px;

    .save {
      margin-right: 16px;
    }
  }
}
