@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-nsi-frnsi-dict-page {
  .loader {
    @include flex(row, center, center);

    padding-top: 300px;
  }
}
