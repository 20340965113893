@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-admin-users-page {
  @include flex(column, flex-start, stretch);

  background-color: white;

  .table-scroll-loader {
    position: relative;
  }

  .admin-users-roles-container {
    display: grid;
    grid-template-columns: 30% auto;
  }

  .register-pretable {
    @include flex(row, flex-start, center);

    padding: 9px;
    color: $color-blue-gray-40;
    font-size: 12px;
    font-weight: 500;

    .register-pretable-tag {
      margin-left: 8px;
    }

    .register-pretable-right-caption {
      margin: 0;
    }
  }

  .admin-users-content {
    position: relative;

    .admin-users-content-loader-overlay {
      @include flex(row, center, center);

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: calc(100vh - 48px - 37px);
      background-color: rgba(0, 0, 0, 0.1);
    }

    .root-admin-users-page-table {
      width: 100%;
      border-right: 1px solid #D5DEDF;
      border-bottom: 1px solid #D5DEDF;
      background-color: white;
      border-collapse: collapse;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      table-layout: fixed;

      .admin-users-table-head {
        background: #FAFBFA;
      }

      .selected-checkbox {
        background: rgba(14, 190, 137, 0.05) !important;
      }

      .selected-user {
        background-color: #E3F1F4 !important;
      }

      tr {
        .table-row-tag-container {
          display: flex;
          height: 55px;
          align-items: center;
          justify-content: flex-start;
        }

        &:nth-child(even) {
          background-color: $color-gray-120;
        }

        &:hover {
          cursor: pointer;
        }

        .table-row-tag {
          width: 70px;
          height: 25px;
        }

        &.table-scroll-loader {
          td {
            text-align: center;
          }
        }

        th {
          padding: 8px;
          border: 1px solid #D5DEDF;
          border-bottom: 0;
          border-left: 0;
          color: #19282B;
          font-size: 13px;
          font-weight: normal;
          line-height: 150%;
          text-align: left;

          &:nth-child(1) {
            width: 40px;
          }

          &:last-child {
            border-right: 0;
          }

          .admin-users-user-list-row-tag {
            width: 75px;
            height: 17px;
          }

          .table-header {
            @include flex(column, flex-start, stretch);

            .table-header-title {
              @include flex(row, space-between, center);

              margin-bottom: 4px;
              color: $color-blue-gray-60;
              font-size: 12px;
              font-weight: 500;
              text-overflow: ellipsis;
              white-space: nowrap;

              .sort-icon {
                width: 23px;
                min-width: 23px;
                height: 23px;
                min-height: 23px;
                padding: 3px;
                border-radius: 50%;
                cursor: pointer;
                transition: background-color 0.1s;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.1);
                }
              }
            }

            .table-header-data {
              display: flex;

              span {
                margin: 0 5px;
              }
            }
          }

          &.services-header {
            padding-top: 18px;
            text-align: center;
          }

          &.view-header {
            padding-top: 18px;
            text-align: center;
          }
        }

        td {
          overflow: hidden;
          max-width: 200px;
          padding: 12px 8px;
          border: 1px solid #E3F1F4;
          color: $color-black-light;
          font-size: 13px;
          line-height: 130%;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.services-cell {
            text-align: center;
          }

          &.view-cell {
            text-align: center;
          }
        }
      }
    }

    .root-admin-users-page-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 48px;
      border-top: 1.5px solid #7799A1;
      background-color: $color-white;
      color: $color-blue-gray-40;
      font-size: 12px;
      font-weight: 500;

      @include flex(row, space-between, center);

      .root-admin-users-page-footer-left {
        @include flex(row, left, center);

        margin-left: 30px;

        input {
          width: 60px;
          height: 30px;
          border: 1px solid #D5DEDF;
          margin-right: 10px;
          margin-left: 20px;
          border-radius: 4px;
          outline: none;
          text-align: center;

          &:hover {
            border-color: #ABC0C2;
          }
        }

        .root-admin-users-page-footer-left-icon {
          margin-left: 20px;
          transform: rotate(180deg);
        }
      }

      .root-admin-users-page-footer-right {
        @include flex(row, right, center);

        div {
          @include flex(row, right, center);
        }

        div:first-child {
          padding-right: 30px;
          border-right: 1px solid #D5DEDF;
        }

        div:last-child {
          p {
            padding-right: 10px;
            padding-left: 20px;
          }

          input {
            width: 60px;
            height: 30px;
            border: 1px solid #D5DEDF;
            margin-right: 65px;
            border-radius: 4px;
            outline: none;
            text-align: center;

            &:hover {
              border-color: #ABC0C2;
            }
          }
        }
      }
    }
  }
}
