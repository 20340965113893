@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-report-page {
  .report-content {
    position: relative;
    background-color: white;

    .report-content-view {
      &.report-content-view-loading {
        overflow: hidden;
        max-height: calc(100vh - 48px - 45px - 38px);
      }
    }

    .report-content-controls {
      @include flex(row, flex-start, center);

      .search {
        width: 200px;
        margin-right: 8px;
      }
    }

    .report-content-loader-overlay {
      position: absolute;
    }

    .report-filter {
      display: flex;
      align-items: flex-end;
      padding: 10px 20px 0;
      margin: 0 -8px;
      font-size: 12px;

      &-item {
        @include flex(column, center, center);

        padding: 0 0 16px 8px;
        margin-right: 32px;

        .report-filter-label {
          color: $color-blue-gray-60;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
      }

      &-label {
        width: 100%;
        padding-bottom: 4px;
        font-size: 12px;
      }

      .react-date-picker {
        width: calc(50% - 8px);
        font-weight: 500;
      }
    }

    table {
      width: 100%;
      background-color: white;
      border-collapse: collapse;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      table-layout: fixed;

      tr {
        &:hover {
          td {
            background-color: #F7F7F7;
            cursor: pointer;
          }
        }

        &:nth-child(even) {
          background: #FAFBFA;
        }

        th {
          padding: 8px;
          padding-top: 9px;
          border: 1px solid #D5DEDF;
          background: #FAFBFA;
          color: $color-blue-gray-60;
          font-size: 12px;
          font-weight: 500;
          line-height: 150%;
          text-align: left;
          vertical-align: top;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }

          .table-header {
            &.table-header-center {
              overflow: hidden;
              text-align: center;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .table-header-container {
              @include flex(row, space-between, flex-start);

              .table-header-title {
                overflow: hidden;
                padding-bottom: 7px;
                color: $color-blue-gray-60;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%;
                text-overflow: ellipsis;
                white-space: nowrap;

                .date-sort-icon {
                  border-radius: 50%;
                  cursor: pointer;
                  transition: background-color 0.1s;

                  &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                  }
                }
              }

              .group {
                padding-bottom: 0 !important;
              }

              .sort-icon {
                width: 23px;
                min-width: 23px;
                height: 23px;
                min-height: 23px;
                padding: 3px;
                border-radius: 50%;
                cursor: pointer;
                transition: background-color 0.1s;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.services-header {
            padding-top: 18px;
            text-align: center;
          }

          &.view-header {
            padding-top: 18px;
            text-align: center;
          }
        }
        th:nth-child(1) { width: 15%; }
        th:nth-child(2) { width: 25%; }
        th:nth-child(3) { width: 15%; }
        th:nth-child(4) { width: 20%; }

        td {
          overflow: hidden;
          max-width: 100px;
          padding: 12px 8px;
          border: 1px solid #E3F1F4;
          border-left: 0;
          color: #19282B;
          font-size: 13px;
          font-weight: normal;
          line-height: 130%;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:last-child {
            border-right: 0;
          }

          &.services-cell {
            text-align: center;
          }

          &.view-cell {
            text-align: center;
          }
        }
      }
    }
  }

  .loader {
    @include flex(row, center, center);

    padding-top: 300px;
  }

  .report-buttons {
    display: flex;
    flex: 1;
    padding: 0 8px 16px 0;

    .root-button {
      padding-right: 12px;
      padding-left: 12px;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}
