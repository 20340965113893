@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.assign-role-drawer {
  .assign-role-drawer-content {
    @include flex(column, flex-start, stretch);

    &-user {
      @include flex(column, flex-start, stretch);

      margin-top: 16px;
      margin-bottom: 8px;

      &-title {
        color: $color-blue-gray-60;
        font-size: 12px;
        font-weight: 500;
        line-height: 160%;
      }

      &-field {
        margin-top: 4px;
      }
    }

    &-role {
      @include flex(column, flex-start, stretch);

      &-title {
        color: $color-blue-gray-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }

      &-field {
        margin-top: 4px !important;
      }
    }

    &-org {
      @include flex(column, flex-start, stretch);

      margin-top: 10px;
      margin-bottom: 8px;

      &:first-of-type {
        margin-top: 0;
      }

      &-title {
        color: $color-blue-gray-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }

      &-field {
        margin-top: 4px;
      }
    }

    &-appointment {
      @include flex(column, flex-start, stretch);

      margin-top: 16px;
      margin-bottom: 8px;

      &-title {
        color: $color-blue-gray-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }

      &-field {
        margin-top: 4px;
      }
    }

    &-is-main {
      @include flex(row, flex-start, center);

      height: 50px;

      &-title {
        margin-left: 8px;
        color: $color-blue-gray-40;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  .assign-role-drawer-error {
    padding: 0 16px 8px;
    color: $color-red;
    font-size: 12px;
  }

  .assign-role-drawer-actions {
    @include flex(row, flex-end, stretch);

    padding: 19px 16px 16px;
    border-top: 1px solid #E3F1F4;
    margin: 0;

    .actions-button {
      margin-left: 8px;
    }
  }

  .ant-drawer-footer {
    padding: 0;
    border-top: 0;
  }
}
