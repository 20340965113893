@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-alert {
  .alert-footer {
    @include flex(row, flex-end, stretch);

    .button-warn {
      border-color: $color-red;
      background-color: $color-red;
    }
  }
}

.root-alert-modal-container {
  position: absolute;
  z-index: 999;

  .opacity-open {
    @keyframes opacity-open {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    animation-duration: 0.2s;
    animation-name: opacity-open;
  }

  .opacity-close {
    @keyframes opacity-close {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    animation-duration: 0.2s;
    animation-name: opacity-close;
  }

  .alert-modal-container-animation-wrapper {
    @include flex(column, center, center);

    width: 500px;
    height: 200px;

    .root-allert-modal {
      @include flex(column, flex-start, stretch);

      z-index: 102;
      width: 100%;
      border: 1px solid #E3F1F4;
      background-color: white;
      border-radius: 8px;
      box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);

      .allert-modal-header {
        @include flex(row, flex-start, stretch);

        position: relative;
        padding: 19px 16px 16px;
        border-bottom: 1px solid #E3F1F4;

        &.no-borders {
          border-bottom: 0;
        }

        .allert-modal-title {
          color: $color-black-light;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
        }

        .allert-modal-close-icon {
          position: absolute;
          top: 19px;
          right: 16px;
          fill: $color-blue-gray-40;
        }
      }

      .allert-modal-content {
        @include flex(column, flex-start, stretch);

        padding: 16px;
      }

      .allert-modal-error {
        padding: 0 16px 8px;
        color: $color-red;
        font-size: 12px;
      }

      .allert-modal-actions {
        @include flex(row, flex-end, stretch);

        padding: 19px 16px 16px;
        border-top: 1px solid #E3F1F4;
        margin-top: auto;

        &.no-borders {
          border-top: 0;
        }

        .delete {
          width: 80px;
          border-color: $color-red;

          .button-label {
            color: $color-red;
          }

          &:hover {
            background-color: rgba($color-red, 0.1);
          }
        }

        .cancel {
          width: 80px;
          margin-left: 16px;
        }
      }
    }

    .root-allert-modal.img-mode {
      overflow: auto;
      width: 80vw;
      min-height: 80vh;
      max-height: 500px;
    }

    .allert-modal-img {
      width: max-content;
      height: max-content;
      cursor: pointer;
    }

    .text-input-wrapper {
      margin: 0 16px;
    }
  }
}
