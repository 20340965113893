@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-tab-routes {
  @include flex(row, flex-start, flex-start);

  .root-routes-main-card {
    min-height: 80vh;

    .card-header {
      padding: 0;
    }

    .card-column-data {
      width: 100%;
      padding: 17px;
    }
  }

  .mr-16 {
    margin-right: 16px;
  }

  .npa-routes-route {
    overflow: scroll;
    background-color: white;

    .npa-routes-route-panel {
      @include flex(row, space-between, center);

      padding: 18px;
      border-bottom: 1px solid #D5DEDF;

      .npa-routes-route-panel-left {
        @include flex(row, flex-start, center);

        width: 60%;

        span {
          margin-right: 10px;
        }
      }

      .npa-routes-route-panel-right {
        @include flex(row, space-between, center);

        width: 40%;

        .npa-routes-route-panel-right-icon {
          margin-right: 18px;

          &:first-of-type {
            margin-left: 18px;
          }
        }
      }
    }
  }

  .npa-routes-main-card-header-data {
    width: 100%;

    .routes-main-card-header-data-container {
      @include flex(row, space-between, center);

      width: 100%;
      padding: 17px 18px;

      .routes-main-card-header-data-row-left {
        @include flex(row, center, center);

        .npa-routes-tag {
          max-width: 400px;
          margin-right: 10px;
        }
      }

      .routes-main-card-header-data-text {
        margin-right: 10px;
        color: $color-blue-gray-40;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        white-space: nowrap;
      }
    }

    .routes-main-card-header-data-text-input {
      padding: 17px 18px;
      border-bottom: 1px solid $color-gray-80;
      font-weight: 100;

      .tab-routes-combox {
        width: 100%;
      }
    }
  }

  .routes-npa-card-tag-title {
    margin-bottom: 8px;
    color: $color-blue-gray-40;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    &:last-of-type {
      margin-top: 24px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  .routes-npa-card-tag-container {
    @include flex(row, left, center);

    margin-bottom: 5px;

    span {
      margin-left: 8px;
    }
  }

  .root-routes-card-container {
    min-width: 300px;
    max-width: 330px;
    margin: 16px;

    .card-column-title {
      width: 55% !important;
    }

    .card-column-data {
      width: 45% !important;
    }

    .root-npa-text-tab-select {
      width: 260px;
    }
  }

  .root-routes-accordion-data-container {
    width: 100%;
    margin: 16px;

    .root-routes-accordion {
      margin-bottom: 17px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.npa-route-image {
  overflow: auto;
  width: 100%;
  height: 80vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
