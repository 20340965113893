@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-radio-group {
  @include flex(column, flex-start, flex-start);

  &-radio {
    @include flex(row, flex-start, center);

    margin: 4px 0;

    label {
      display: block;
      margin-left: 8px;
      color: $color-blue-gray-40;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 150%;

      &.disabled {
        color: $color-grey;
      }
    }
  }
}

.row {
  flex-direction: row;

  .root-radio-group-radio {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

input[type="radio"]:checked {
  border: 5px solid $color-green-80;
}

input[type="radio"] {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #D7D7D7;
  margin: -0.15px 0.6px 0 0;
  appearance: none;
  background-clip: border-box;
  background-color: #FFF;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: border 0.25s, box-shadow 0.25s;
}
