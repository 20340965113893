@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.app-loading {
  @include flex(row, center, center);

  width: 100vw;
  height: 100vh;
}

.ant-select-item-option-selected {
  font-weight: normal !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #CFE6D7;
}

%scrollbar {
  width: 6px !important;
  background: #C5C5C5 !important;
  border-radius: 20px !important;
}

.rc-virtual-list-scrollbar {
  .rc-virtual-list-scrollbar-thumb {
    @extend %scrollbar;
  }
}

.ant-select-tree-list-scrollbar {
  .ant-select-tree-list-scrollbar-thumb {
    @extend %scrollbar;
  }
}

.ant-form {
  .ant-form-item-label {
    .ant-form-item-required {
      display: flex;

      &::before {
        order: 1;
        margin-left: 4px;
      }
    }
  }

  label {
    color: #7B868C;
    font-size: 12px;
    font-weight: 500;
  }
}
