@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .root-checkbox {
    width: 17px;
    height: 17px;
    text-align: center;
  }

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border: 1px solid #E1E5EB;
    appearance: none;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    outline: 0;
  }

  input[type="checkbox"]:checked {
    border-color: #009866;
    background: #009866;
  }

  input[type="checkbox"]:hover {
    filter: brightness(90%);
  }

  input[type="checkbox"]::after {
    position: relative;
    top: -4px;
    left: 2px;
    display: none;
    width: 0;
    height: 22px;
    min-height: 22px;
    border-width: 0 2px 2px 0;
    background-color: white;
    color: white;
    content: "\2714";
    transform: rotate(9deg);
  }

  input[type="checkbox"]:checked::after {
    display: block;
    background-color: white;
  }

  &.disabled {
    input[type="checkbox"] {
      border-color: #E1E5EB;
      background: #E1E5EB;
    }
  }
}
