@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-theses-tab {
  @include flex(row, flex-start, flex-start);

  width: 100%;
  background: #F7F9F7;

  .theses-card-container {
    min-width: 300px;
    margin: 16px;

    & > div:first-of-type {
      width: 300px;
    }

    .card-column-title {
      width: 55% !important;
    }

    .card-column-data {
      width: 45% !important;
    }
  }

  .theses-card-data-container {
    width: calc(100vw - 400px);
    border: 1px solid $color-gray-80;
    border-bottom: 0;
    margin: 18px;
    margin-left: 0;
    border-radius: 12px;

    .theses-tab-table-header {
      display: grid;
      width: calc(100% - 14px);
      grid-gap: 0;
      grid-template-columns: 15% 15% 15% 15% 40%;
      grid-template-rows: repeat(1, auto);

      .theses-tab-table-header-container {
        @include flex(column, space-between, flex-start);

        height: 100%;
        min-height: 35px;
        padding: 9px;
        border-right: 1px solid $color-gray-80;
        border-bottom: 1px solid $color-gray-80;
        background: $color-gray-120;
        color: $color-blue-gray-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        vertical-align: bottom;
        word-break: break-all;

        .theses-tab-table-header-container-title {
          word-break: break-word;
        }

        .theses-tab-search {
          margin-top: 8px;
        }

        &:first-child {
          border-left: 0;
          border-top-left-radius: 12px;
        }

        &:last-child {
          border-right: 0;
          border-top-right-radius: 12px;
        }
      }
    }

    .root-theses-tab-table {
      display: grid;
      width: 100%;
      min-height: 155px;
      max-height: calc(100vh - 296px);
      border-radius: 12px;
      grid-gap: 0;
      grid-template-columns: 15% 15% 15% 15% 40%;
      grid-template-rows: repeat(1, auto);
      overflow-y: scroll;

      .grid-cell {
        padding: 8px;
        border-right: 1px solid $color-gray-80;
        border-bottom: 1px solid $color-gray-80;
        background-color: white;
        color: #19282B;
        font-size: 13px;
        font-style: normal;
        font-weight: normal;
        line-height: 120%;
        overflow-wrap: break-word;
        text-align: left;
        vertical-align: top;

        &.col-disabled {
          opacity: 0.4;
        }

        &.cell-last {
          padding: 0;
          border-right: 0;

          .cell-last-container {
            @include flex(row, flex-start, flex-start);

            width: 100%;
            height: 100%;

            span {
              height: 100%;

              &:first-child {
                min-width: 135px;
                max-width: 135px;
                padding: 8px;
                border-right: 1px solid $color-gray-80;
                border-bottom: 1px solid $color-gray-80;
              }

              &:last-of-type {
                overflow: hidden;
                width: 100%;
                padding: 13px 12px;
                border-bottom: 1px solid $color-gray-80;
              }
            }

            &:last-child {
              span {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
