@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.main-page-root {
  @include flex(row, flex-start, flex-start);

  overflow: hidden;
  height: 100vh;

  .main-page-view {
    position: relative;
    top: 48px;
    height: calc(100% - 48px);
    flex-grow: 1;
    padding-left: 48px;
    background-color: $color-background-main;
    overflow-x: clip;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1000px) {
  .main-page-view {
    overflow-x: auto !important;
  }
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background: #C5C5C5;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #AAA;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
