@import "@src/styles/variables.scss";

.root-badge {
  display: flex;
  min-width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  border: 1px solid $color-green-80;
  background-color: $color-green-100;
  border-radius: 20px;

  & > span {
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    user-select: none;
  }
}
