.root-record-modal {
  .ant-modal-body {
    padding: 0;
  }

  .record-modal {
    height: 700px;
    max-height: calc(100vh - 120px);

    .record-modal-document {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .record-modal-button {
    max-width: max-content;
    margin-left: auto;
  }
}
