@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-journal-semd-details-outgoing-tab {
  .table-action {
    max-width: 80%;
    padding-top: 2px;
    color: $color-blue-grey;
    cursor: pointer;
    font-size: 14px;
    line-height: 150%;
    text-decoration: underline;
  }
}
