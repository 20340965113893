@import "@src/styles/variables.scss";

.root-refbook-value-selector-drawer {
  .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .ant-drawer-header {
      border-bottom: 1px solid #DFE3E6;

      .ant-drawer-header-title {
        flex-direction: row-reverse;
      }
    }

    .ant-drawer-body {
      overflow: hidden;
      max-height: 100%;
      padding: 0;
    }

    .ant-drawer-footer {
      border-top: 1px solid #DFE3E6;
    }
  }

  .value-selector-filters {
    border-bottom: 1px solid $color-gray-100;
  }
}

.root-value-selector-panel {
  display: flex;
  height: calc(100% - 65px); // минус заголовок с фильтрами
  flex-direction: column;

  .selector-panel-column {
    width: 50%;
    height: 100%;
  }
}

.root-pane {
  height: 100%;
  border-right: 1px solid $color-gray-100;

  .pane-headers {
    border-bottom: 1px solid $color-gray-100;
    background-color: $color-gray-120;
    gap: 0;
    overflow-y: hidden;
    scrollbar-gutter: stable;

    & > div {
      &:not(:last-child) {
        border-right: 1px solid $color-gray-100;
      }

      padding: 8px;
    }
  }

  .pane-values {
    overflow-y: scroll !important;

    .pane-value-row {
      border-bottom: 1px solid $color-gray-100;
      gap: 0;

      & > div {
        &:not(:last-child) {
          border-right: 1px solid $color-gray-100;
        }

        overflow: hidden;
        padding: 8px;

        &:not(.pane-value-row-actions):hover {
          overflow: visible;
          background-color: $color-gray-120;
        }

        /* stylelint-disable-next-line selector-class-pattern */
        &.ref_item_id, &.ref_item_code {
          span {
            padding: 4px 8px;
            background-color: $color-gray-100;
            border-radius: 4px;
          }
        }

        /* stylelint-disable-next-line selector-class-pattern */
        &.ref_item_id, &.ref_item_code, &.ref_item_name {
          position: relative;
          z-index: 100;

          &:hover {
            z-index: 200;
          }
        }
      }

      .pane-value-row-actions {
        display: flex;
        justify-content: center;

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
