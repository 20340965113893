.root-mo-modal {
  .mo-modal-body-block {
    display: flex;
    flex-direction: column;

    .mo-modal-body-block-title {
      color: #7B868C;
      font-size: 12px;
      font-weight: 500;
    }

    .mo-modal-body-sub-block {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
  }

  .mo-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}
