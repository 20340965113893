@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

$panels-height: calc(100vh - 230px);

.root-admin-rooles-page-actions > div {
  @include flex(row, flex-start, center);

  padding: 16px;
  background-color: white;

  div:first-child {
    margin-right: 10px;
  }
}

.root-admin-rooles-page-rooles {
  @include flex(row, flex-start, flex-start);

  border-top: 1px solid #D5DEDF;

  .admin-roles-resources {
    width: 100%;
    max-width: 500px;
    height: 80.6vh;
    border: 1px solid #D5DEDF;
    margin: 16px;
    background-color: white;
    border-radius: 8px;
    overflow-y: auto;

    .root-admin-search-container {
      @include flex(row, flex-start, flex-start);

      width: 100%;

      .search {
        margin: 15px !important;
        margin-bottom: 0 !important;
      }
    }

    &-title {
      @include flex(row, flex-start, center);

      height: 53px;
      padding: 8px;
      border-bottom: 1px solid #D5DEDF;
      color: $color-blue-gray-60;
      font-size: 12px;
      font-weight: 500;
    }

    ul, li {
      font-size: 14px;
      list-style-type: none;
    }

    &-tree {
      padding-left: 0;
      margin-top: 8px;

      li {
        padding: 10px 25px;
        cursor: pointer;

        &.selected {
          background: #ECF2F0;
          color: #000;
          font-weight: 500;
        }
      }

      .expand {
        width: 8px;
        height: 16px;
        margin-right: 6px;
        color: $color-grey;
        cursor: pointer;
        float: left;
        font-size: 14px;
        font-weight: 500;

        &.placeholder {
          color: transparent;
        }

        .expand-icon {
          position: relative;
          right: 10px;
          bottom: 3px;
          width: 21px;
          height: 21px;
        }
      }
    }

    .subtree-recource {
      @include flex(row, flex-start, center);

      .subtree-checkbox {
        margin-right: 6px;
      }
    }
  }

  .admin-roles-acl {
    width: 100%;
    height: 82.6vh;
    border-left: 1px solid #D7E2E9;
    background-color: white;
    color: $color-blue-gray-40;
    overflow-x: hidden;
    overflow-y: auto;

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;

      thead {
        tr {
          th {
            height: 35px;
            border: 1px solid #D5DEDF;
            border-top: 0;
            background: #FAFBFA;

            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }
        }
      }

      tbody.admin-roles-acl-row {
        height: 160px;

        label {
          display: block;
          margin-left: 8px;

          &.disabled {
            color: $color-grey;
          }
        }

        .admin-roles-acl-row-cb {
          @include flex(row, flex-start, center);
        }
      }

      tr {
        th {
          height: 53px;
          padding: 8px;
          background-color: #E3F1F4;
          font-size: 12px;
          font-weight: 500;
          text-align: left;
        }
        th:nth-child(1) { width: 33%; }
        th:nth-child(2) { width: 33%; }
        th:nth-child(3) { width: 34%; }

        td {
          padding: 8px;
          border: 1px solid #E3F1F4;
          color: inherit;
          font-size: 13px;

          &:first-child {
            border-left: 0;
          }

          .admin-roles-page-roles-radio-group {
            display: flex;
            height: 140px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
          }
        }
      }
    }
  }

  .error {
    color: $color-red;
  }
}
