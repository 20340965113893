@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-reg-conditions {
  @include flex(column, flex-start, stretch);

  position: relative;
  width: calc(100% - 450px - 16px);
  height: calc(100vh - 122px);
  border: 1px solid #D5DEDF;
  background-color: white;
  border-radius: 8px;

  .root-reg-conditions-body {
    padding: 12px 16px 0 12px;

    &::-webkit-scrollbar {
      border-top-right-radius: 8px;
    }

    .foundation-npa {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;

      .foundation-npa-title {
        width: 230px;
        margin-right: 16px;
        color: $color-blue-gray-40;
        font-size: 12px;
        font-weight: 500;
        text-wrap: nowrap;
      }

      .foundation-npa-select {
        width: calc(100% - 230px - 16px);
        margin-left: auto;
      }
    }

    .root-territorial-condition {
      border-color: $color-blue-gray-120;
      background-color: $color-gray-100;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .territorial-condition-container {
        padding: 0;
        border-color: $color-blue-gray-120;

        .territorial-condition-empty {
          height: auto;

          & .empty-state-container {
            margin-top: 0;
          }

          &-description .empty-state-description {
            max-width: 600px;
          }

          & * {
            color: #B0BABF;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .territorial-condition-block {
          @include flex(row, center, flex-start);

          flex-wrap: wrap;

          .territorial-condition-subblock {
            @include flex(column, flex-start, center);

            width: 0;
            flex: 1 1 0;

            &:nth-child(n+2) {
              border-left: 1px solid $color-gray-80;
            }

            &:last-child .territorial-condition-subblock-body::-webkit-scrollbar {
              border-bottom-right-radius: 8px;
            }

            .territorial-condition-subblock-header {
              @include flex(row, space-between, center);

              width: 100%;
              flex-wrap: wrap;
              padding: 8px 16px;
              border-bottom: 1px solid $color-gray-80;
              background-color: $color-gray-120;

              .territorial-condition-subblock-header-caption {
                overflow: hidden;
                width: calc(100% - 96px);
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .territorial-condition-subblock-header-board {
                @include flex(row, center, center);

                margin: -5px 0;

                .territorial-condition-subblock-header-board-icon {
                  width: 32px;
                  min-width: 32px;
                  height: 32px;
                  min-height: 32px;
                  padding: 7px;
                  border: 1px solid $color-gray-60;
                  border-radius: 50%;
                  transition: background-color 0.1s;

                  &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                  }
                }
              }

              .territorial-condition-subblock-header-search {
                width: 100%;
                margin: 14px 0 0;
              }
            }

            .territorial-condition-subblock-body {
              width: 100%;
              height: 215px;

              .empty-state-container {
                margin-top: 35px;
              }

              .territorial-condition-subblock-body-row {
                @include flex(row, space-between, center);

                width: 100%;
                height: 40px;
                border-bottom: 1px solid $color-gray-80;

                .territorial-condition-subblock-body-row-caption {
                  overflow: hidden;
                  width: 100%;
                  height: 100%;
                  padding: 8px 16px;
                  font-size: 13px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .territorial-condition-subblock-body-row-board {
                  @include flex(row, space-between, center);

                  padding: 8px;
                  border-left: 1px solid $color-gray-80;

                  .territorial-condition-subblock-body-row-board-icon {
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    min-height: 24px;
                    padding: 4px;
                    border-radius: 50%;
                    transition: background-color 0.1s;

                    &:hover {
                      background-color: rgba(0, 0, 0, 0.1);
                    }
                  }
                }

                &:hover {
                  background-color: rgba(0, 0, 0, 0.03);
                }
              }
            }
          }
        }
      }
    }

    .root-new-territorial-condition {
      display: flex;
      height: 52px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 16px;
      border: 1px dashed $color-green-100;
      margin: 16px 0;
      border-radius: 4px;
      color: $color-green-80;
      cursor: pointer;
      text-decoration: underline;
      transition: background-color 0.2s;

      &:hover {
        background-color: rgba($color-green-100, 0.05);
      }

      .root-icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }

  .action-menu {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    padding: 8px;
    border: 1px solid $color-gray-120;
    border-radius: 50%;
    transition: border-color 0.2s ease, background-color 0.2s ease;

    & * {
      fill: #42474D;
    }

    &:hover {
      border-color: $color-blue-gray-40;
    }

    &:active {
      background-color: $color-gray-80;
    }

    &.disabled {
      background-color: inherit;
      transition: none;

      &:hover {
        border: 0;
      }

      &:active {
        background-color: inherit;
      }
    }
  }

  .bottom-buttons {
    z-index: 5;
    right: 0;
    bottom: 16px;
    height: 65px;
    padding: 16px 12px;
    border-top: 1px solid #D5DEDF;
    background-color: #FFF;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .root-time-condition {
    .time-condition-beginning-countdown {
      display: flex;
      flex-direction: column;

      .time-condition-beginning-countdown-container {
        display: flex;
        align-items: center;

        .time-condition-beginning-countdown-attribute {
          flex-basis: 56%;
        }

        .time-condition-beginning-countdown-field-small {
          min-width: 40px;
          flex-basis: 9%;
        }

        .time-condition-beginning-countdown-field {
          flex-basis: 16%;
        }

        .time-condition-beginning-countdown-operation {
          flex-basis: 4%;
        }

        .time-condition-beginning-countdown-measurement {
          flex-basis: 20%;
        }
      }
    }
  }

  .card-header {
    background-color: $color-gray-100;
  }
}
