@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-deviations-drawer {
  max-height: 100%;

  .ant-drawer-body {
    @include flex(column, flex-start, stretch);

    padding: 0;
  }

  .root-tag-component {
    font-weight: normal;
  }

  .deviations-drawer-header {
    @include flex(row, flex-start, flex-start);

    padding: 16px;
    padding-bottom: 0;

    .header-title {
      margin-right: 12px;
      color: $color-black-80;
      font-size: 18px;
      font-weight: 500;

      .deviations-drawer-tag {
        display: inline-block;
        margin-left: 12px;
        vertical-align: text-bottom;
      }
    }
  }

  .deviations-controls {
    @include flex(row, flex-start, center);

    padding: 0 20px;
    margin: 16px 0;

    .deviation-date-picker {
      min-width: 245px;
      margin-right: 16px;
    }

    .deviations-search {
      width: 245px;
      margin-right: 16px;
    }
  }

  .deviations-drawer-tabs {
    .tab-label {
      @include flex(row, flex-start, center);
    }

    .tab-tag {
      margin-left: 4px;
      border-radius: 4px;
      color: $color-blue-gray-40;
      font-weight: 400;
    }
  }

  .deviations-drawer-body {
    flex-grow: 1;
    padding: 16px 20px;
    background-color: $color-gray-120;
    overflow-y: scroll;

    .deviation-card {
      .card-header {
        height: 50px;
      }

      .card-column {
        background-color: $color-white;

        .card-column-data {
          width: 100%;
        }
      }

      .deviation-title {
        @include flex(row, flex-start, center);

        font-size: 13px;
      }
    }
  }
}
