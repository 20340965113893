.scroll-y {
  position: relative;
  overflow-y: scroll;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-y,.scroll-x {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    overflow: hidden;
    width: 14px;
    height: 12px;
    background-color: #FFF;
  }

  &::-webkit-scrollbar-track {
    background: #FFF;
    outline: 1px solid $color-gray-80;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid #FFF;
    background-color: $color-blue-gray-100;
    border-radius: 20px;
    transition: background-color 0.3s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $color-blue-gray-80;
  }

  &::-webkit-scrollbar-button:horizontal:end:increment {
    width: 12px;
    height: 10px;
    background-image: url("../../assets/icons/horizontalRightButton.png");
    background-position: center;
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:horizontal:start:decrement {
    width: 12px;
    height: 10px;
    background-image: url("../../assets/icons/horizontalLeftButton.png");
    background-position: center;
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    width: 10px;
    height: 12px;
    background-image: url("../../assets/icons/scroll-vertical-bottom-button.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:start:decrement {
    width: 10px;
    height: 12px;
    background-image: url("../../assets/icons/scroll-vertical-top-button.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}
