@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-internal-error {
  @include flex(column, center, center);

  width: 100%;
  height: 100%;

  h1 {
    margin-bottom: 10px;
    color: $color-blue-gray-40;
    font-size: 72px;
    line-height: 120%;
  }

  h2 {
    max-width: 516px;
    margin: 0;
    color: $color-blue-gray-40;
    font-size: 21px;
    text-align: center;
    white-space: pre-wrap;
  }

  .error-icon {
    width: 60px;
    height: 60px;
  }

  .error-content {
    display: flex;
    max-width: 666px;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    padding-bottom: 0;
    padding-left: 0;
    border: 1px solid $color-gray-80;
    background: $color-white;
    border-radius: 8px;
    gap: 24px;

    .error-content-header {
      display: flex;
      width: 70px;
      height: 25px;
      align-items: flex-start;
      padding: 4px 6px;
      background: $color-gray-100;
      border-radius: 4px;
      color: #1C3D45;
      line-height: 130%;
    }

    .error-content-reasons {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 0;

      * {
        width: 279px;
      }
    }
  }
}
