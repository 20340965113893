$color-primary: #00A65A;
$color-white: #FFF;
$color-red: #ED4337;
$color-blue-grey: #7B9CB7;
$color-blue: #0082E9;
$color-blue-light: #F2FFFD;
$color-black-light: #4A4D61;
$color-grey: #8D8F9B;
$color-row-bg-warning: antiquewhite;
$color-background-main: #F6FAF7;

/* Green */

$color-green-40: #005127;
$color-green-60: #007445;
$color-green-80: #009866;
$color-green-100: #0EBE89;
$color-green-120: #86DEC4;
$color-green-140: #C2EEE1;

/* Blue */

$color-blue-40: #00276D;
$color-blue-60: #00438F;
$color-blue-80: #0062B3;
$color-blue-100: #1B82D9;
$color-blue-120: #56A5FF;

/* BlueGray */

$color-blue-gray-40: #1C3D45;
$color-blue-gray-60: #36575F;
$color-blue-gray-80: #56777F;
$color-blue-gray-100: #7799A1;
$color-blue-gray-120: #99BCC5;
$color-blue-gray-140: #BCE0E9;

/* Gray */

$color-gray-60: #ABC0C2;
$color-gray-80: #D5DEDF;
$color-gray-100: #ECF2F0;
$color-gray-120: #FAFBFA;

/* Orange */

$color-orange-40: #840300;
$color-orange-60: #AC320C;
$color-orange-80: #D5562D;
$color-orange-100: #FF794D;

/* Black */

$color-black-80: #2D3034;

/* Notification color */

$color-notification-success: #E8FFF7;
$color-notification-error: #FFE8E8;
$color-notification-warning: #FFFAE8;
$color-notification-info: #E8F9FF;
