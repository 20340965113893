@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-update-timestamp {
  @include flex(row, flex-start, center);

  min-width: 450px;
  max-width: max-content;
  height: 32px;
  min-height: 32px;
  padding: 2px 12px;
  background: #F2FAF7;
  border-radius: 5px;
  color: $color-blue-gray-40;
  cursor: pointer;
  font-size: 13px;
  opacity: 1;
  transition: opacity 0.2s;

  &.timestamp-loading {
    opacity: 0.5;
  }

  .timestamp-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
