@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-combobox {
  .ant-select-selection-search-input, .ant-select-selection-placeholder {
    font-weight: normal;
  }

  .ant-select-selection-overflow {
    overflow: hidden;
    flex-wrap: nowrap;

    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
      max-width: 90%;

      .ant-select-selection-item {
        padding: 0;
        border: none;
        margin: 0;
        margin-left: 6px;
        background-color: transparent;

        .ant-select-selection-item-content {
          overflow: hidden;
          margin-top: auto;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
