@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-app-notification-stack {
  position: absolute;
  z-index: 1001;
  bottom: 0;
  left: 25px;
  height: auto;
  margin-top: 10px;

  .notification-button {
    position: relative;
    z-index: 999;
    bottom: 20px;
    left: 400px;
    width: max-content;
    padding: 4px 5px;
    border-radius: 5px;
    color: $color-primary;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: rgba($color-primary, 0.096);
    }
  }
}
