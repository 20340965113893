@mixin flex ($flex-direction, $justify-content, $align-items) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

$spaceamounts: (0, 2, 4, 8, 12, 16, 20, 24, 32, 44, 56);
$sides: (top, bottom, left, right, all, x, y);

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == "all" {
      .ma-#{$space} {
        margin: #{$space}px;
      }

      .pa-#{$space} {
        padding: #{$space}px;
      }
    } @else if $side == "x" {
      .mx-#{$space} {
        margin-right: #{$space}px;
        margin-left: #{$space}px;
      }

      .px-#{$space} {
        padding-right: #{$space}px;
        padding-left: #{$space}px;
      }
    } @else if $side == "y" {
      .my-#{$space} {
        margin-top: #{$space}px;
        margin-bottom: #{$space}px;
      }

      .py-#{$space} {
        padding-top: #{$space}px;
        padding-bottom: #{$space}px;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}

@for $i from 0 through 300 {
  .w-#{$i} {
    width: #{$i}px;
  }
  .maxw-#{$i} {
    max-width: #{$i}px;
  }
  .minw-#{$i} {
    min-width: #{$i}px;
  }
  .h-#{$i} {
    height: #{$i}px;
  }
  .maxh-#{$i} {
    max-height: #{$i}px;
  }
  .minh-#{$i} {
    min-height: #{$i}px;
  }
}

.w-100p {
  width: 100%;
}

.h-100p {
  height: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

@mixin data-grid-no-left-borders {
  .root-data-grid {
    .grid-table-header {
      border-left: 0;
    }

    .grid-table-body-row {
      border-left: 0;
    }
  }
}

@mixin dropdown-shadow {
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
}
