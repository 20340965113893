@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-range-input {
  @include flex(row, space-between, center);

  position: relative;
  width: 100%;
  height: 32px;

  .range-input-input {
    width: 45%;
    height: 100%;
    padding-left: 6px;
    border: 1px solid #D7E2E9;
    background-color: white;
    border-radius: 4px;
    color: $color-blue-gray-40;
    font-size: 12px;
    font-weight: normal;
    line-height: 150%;
    transition: border 0.3s, box-shadow 0.3s;

    &:focus {
      outline: none !important;
    }

    &::placeholder {
      color: #BFBFBF;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
    }

    &:focus-within {
      border: 1px solid $color-primary;
      box-shadow: 0 0 3px #D5DEDF;
    }

    &:hover {
      border: 1px solid $color-primary;
    }
  }
}
