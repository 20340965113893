@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-admin-users-roles {
  @include flex(column, flex-start, stretch);

  margin-left: 16px;

  .admin-users-roles {
    border-top: 1px solid #D5DEDF;
    background-color: #FFF;
    color: #32343F;
    font-size: 14px;
    font-weight: normal;

    .roles-table-container {
      position: relative;
      min-height: 250px;

      table {
        width: 100%;
        border-collapse: collapse;
        overflow-x: scroll;
        table-layout: fixed;

        thead, tbody {
          border-left: 1px solid #D5DEDF;
        }

        tr {
          cursor: pointer;

          &:nth-child(even) {
            background: #FAFBFA;
          }

          &.selected-role {
            background-color: #E3F1F4;
          }

          th {
            padding: 12px;
            border-right: 1px solid #D5DEDF;
            border-bottom: 1px solid #D5DEDF;
            background-color: #FAFBFA;
            color: $color-blue-gray-40;
            font-size: 12px;
            font-weight: 500;
            text-align: left;

            &:last-child {
              border-right: 0;
            }
          }
          th:nth-child(1) { width: 26%; }
          th:nth-child(2) { width: 20%; }
          th:nth-child(3) { width: 10%; }
          th:nth-child(4) { width: 10%; }
          th:nth-child(5) { width: 34%; }

          td {
            overflow: hidden;
            padding: 8px;
            border: 1px solid #D5DEDF;
            border-left: 0;
            color: $color-black-light;
            text-overflow: ellipsis;

            &:last-child {
              border-right: #D5DEDF 0;
            }

            &.is-main {
              text-align: center;
            }

            &.actions {
              text-align: center;

              img {
                padding: 3px;
                border-radius: 50%;
                cursor: pointer;
                transition: background-color 0.1s;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.1);
                }
              }

              .action-edit {
                margin-right: 8px;
              }
            }
          }

          &.row-add {
            background-color: rgba(#E3F1F4, 0.3);
            text-align: center;
            transition: background-color 0.2s;

            &:hover {
              background-color: rgba(#E3F1F4, 0.7);
            }
          }
        }

        .loader-container {
          cursor: auto;

          &:hover {
            background-color: #FFF;
          }

          td {
            border: none;
            text-align: center;

            .loader {
              padding-top: 200px;
            }
          }
        }
      }
    }
  }

  .roles-button-container {
    @include flex(row, flex-end, stretch);

    padding: 9px;

    & > .root-button {
      height: 32px;
      margin-right: 8px;
    }
  }
}
