@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-masked-text-input {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #D7E2E9;
  background-color: white;
  border-radius: 4px;
  transition: border 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s;

  &.root-masked-text-input-invalid {
    border-color: #FA4547 !important;
  }

  .masked-text-input-input {
    width: calc(100% - 24px);
    height: 100%;
    padding-left: 6px;
    border: none;
    border-radius: 4px;
    color: $color-blue-gray-40;
    font-size: 12px;
    font-weight: normal;
    line-height: 150%;

    &:focus {
      outline: none !important;
    }

    &::placeholder {
      color: #BFBFBF;
      font-size: 12px;
      font-weight: normal;
      line-height: 150%;
    }
  }

  .masked-text-input-icon {
    position: absolute;
    top: 6px;

    // right: 8px;
    width: 16px;
    height: 16px;
    pointer-events: none;
  }

  .masked-text-input-icon-clear {
    position: absolute;
    top: 6px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: #7799A1;
  }

  &:focus-within {
    border: 1px solid $color-primary;
    box-shadow: 0 0 3px #D5DEDF;
    outline: none !important;
  }

  &:hover {
    border: 1px solid $color-primary;
  }
}
