@for $i from 0 through 100 {
  .pd-#{$i} {
    padding: #{$i}px !important;
  }

  .pd-t-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pd-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .pd-l-#{$i} {
    padding-left: #{$i}px !important;
  }

  .pd-r-#{$i} {
    padding-right: #{$i}px !important;
  }

  .pd-y-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .pd-x-#{$i} {
    padding-right: #{$i}px !important;
    padding-left: #{$i}px !important;
  }

  .mg-#{$i} {
    margin: #{$i}px !important;
  }

  .mg-t-#{$i} {
    margin-top: #{$i}px !important;
  }

  .mg-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .mg-l-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mg-r-#{$i} {
    margin-right: #{$i}px !important;
  }

  .mg-y-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .mg-x-#{$i} {
    margin-right: #{$i}px !important;
    margin-left: #{$i}px !important;
  }

  .top-#{$i} {
    top: #{$i}px;
  }

  .bottom-#{$i} {
    bottom: #{$i}px;
  }

  .right-#{$i} {
    right: #{$i}px;
  }

  .left-#{$i} {
    left: #{$i}px;
  }

  .width-#{$i} {
    width: #{$i}px;
  }

  .height-#{$i} {
    height: #{$i}px;
  }
}
