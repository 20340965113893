@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-admin-externals-page {
  .admin-externals-content {
    position: relative;
    background-color: #FFF;

    .admin-externals-content-loader-overlay {
      @include flex(row, center, center);

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .admin-externals-content-view {
      @include flex(column, flex-start, stretch);

      margin-bottom: 30px;

      &.admin-externals-content-view-loading {
        overflow: hidden;
        max-height: calc(100vh - 48px - 45px - 38px);
      }

      .admin-externals-actions {
        @include flex(row, space-between, center);

        padding: 15px;

        .admin-externals-actions-left, .admin-externals-actions-right {
          @include flex(row, flex-start, center);
        }

        .admin-externals-actions-left > div:first-child {
          margin-right: 15px;
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        tr {
          cursor: pointer;

          &:hover {
            background-color: #F7F7F7;
          }

          &:nth-child(even) {
            background: #FAFBFA;
          }

          th {
            padding: 8px;
            border: 1px solid #D5DEDF;
            background: #FAFBFA;
            color: $color-blue-gray-60;
            font-size: 12px;
            font-weight: 500;
            text-align: left;
            word-break: break-all;
          }

          th:first-child {
            border-left: 0;
          }
          th:nth-child(1) { width: 18%; }
          th:nth-child(2) { width: 23%; }
          th:nth-child(3) { width: 7%; }
          th:nth-child(4) { width: 9%; }
          th:nth-child(5) { width: 9%; }
          th:nth-child(6) { width: 15%; }
          th:nth-child(7) { width: 3%; }

          td {
            overflow: hidden;
            padding: 8px;
            border: 1px solid #D5DEDF;
            color: #19282B;
            font-size: 13px;
            font-weight: normal;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.actions {
              text-align: center;

              .external-more-icon {
                width: 30px;
                height: 30px;
                border-radius: 20%;
                cursor: pointer;
                transition: background-color 0.1s;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          td:first-child {
            border-left: 0;
          }

          td:last-child {
            text-overflow: clip;
          }

          .align-center {
            text-align: center;
          }
        }
      }
    }
  }
}
