@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.sidebar-menu-entry {
  @include flex(row, flex-start, center);

  width: 100%;
  padding: 16px;
  padding-left: 7px;
  cursor: pointer;

  &:last-child {
    padding-bottom: 30px;
  }

  &:hover {
    background-color: #F6FAF7;
  }

  .sidebar-menu-multiicon {
    width: 31px;
    height: 31px;
    padding: 3px;
    border: 1px solid white;
    background: white;
    border-radius: 8px;
  }

  &.active {
    background-color: #F3FCF9;

    .sidebar-menu-multiicon {
      width: 31px;
      height: 31px;
      padding: 3px;
      border: 1px solid #0EBE89;
      background: rgba(194, 238, 225, 0.3);
      border-radius: 8px;
    }
  }

  .sidebar-menu-label {
    overflow: hidden;
    margin-left: 16px;
    color: #36575F;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.active {
      color: $color-green-80;
    }
  }

  .sidebar-menu-chevron {
    margin-left: auto;
    color: #ACB1C1;
    font-size: 15px;
    line-height: 22.5px;
  }
}
