@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-clinrec-information-tab {
  max-width: 550px;
  margin: 18px;

  .clinrec-information-tab-tag {
    word-break: break-all;
  }
}
