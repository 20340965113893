.grid-table-wrap {
  margin: 16px 0 28px;

  $gray-bg: rgba(246, 248, 250, 1);
  $border: #CBCED7;

  .grid-12 {
    grid-gap: 0;
  }

  .df {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .w50 {
    width: calc(50% - 8px);
  }

  .grid-table {
    overflow: hidden;
    border-top: 1px solid $border;
    border-right: 1px solid $border;
    border-left: 1px solid $border;
    border-radius: 8px;
  }

  .gt-title {
    margin-bottom: 12px;
  }

  .grid-col {
    padding: 9px 7px;
    border-bottom: 1px solid $border;

    &:not(:last-child) {
      border-right: 1px solid $border;
    }

    p {
      margin-bottom: 0;
    }

    &.header-col {
      background-color: $gray-bg;

      p {
        color: var(--black-ll);
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}

.block-grid-wrap {
  $gray-bg: rgba(246, 248, 250, 1);
  $border: #CBCED7;

  padding: 16px 0;

  .gt-title {
    margin-bottom: 12px;
  }

  .column {
    .column-inner {
      padding: 12px;
      background-color: $gray-bg;
      border-radius: 8px;
    }

    .block {
      margin-bottom: 12px;
    }
  }
}
