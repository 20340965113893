@import "@src/styles/variables.scss";

.root-columns-settings-overlay {
  .ant-popover-content {
    border: 1px solid #E3F1F4;
    background-color: white;
    border-radius: 12px;
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);

    .ant-popover-arrow-content {
      border: 1px solid #E3F1F4;
    }

    .ant-popover-inner {
      .ant-popover-inner-content {
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
      }
    }
  }

  .column-settings-search {
    padding-bottom: 8px;
    border-bottom: 1px solid $color-gray-100;
    margin-right: 16px;
    margin-bottom: 8px;
    margin-left: 16px;
  }

  .column-settings-switches {
    overflow: auto;
    max-height: 500px;

    .switch-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 16px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
