@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-mkb10-input {
  position: relative;
  width: 100%;

  .mkb-input-wrap {
    @include flex(row, flex-start, center);

    overflow: hidden;
    width: 100%;
    min-height: 32px;
    max-height: 32px;
    box-sizing: border-box;
    flex-wrap: nowrap;
    padding: 0 26px 0 4px;
    border: 1px solid $color-gray-80;
    background-color: $color-white;
    border-radius: 4px;
    transition: border 0.3s, box-shadow 0.3s;

    .mkb-input {
      min-width: 70px;
      flex-grow: 1;
      border: none;
      color: #19282B;

      &:focus {
        outline: none;
      }
    }

    &:focus-within {
      border: 1px solid $color-primary;
      box-shadow: 0 0 3px #D5DEDF;
      outline: none !important;
    }

    &:hover {
      border: 1px solid $color-primary;
    }

    .mkb-input-tag {
      @include flex(row, center, center);

      max-width: max-content;
      height: 20px;
      padding: 0 5px;
      margin: 2px;
      background: #E0F6E5;
      border-radius: 5px;
      color: $color-blue-gray-40;
      font-size: 10px;
      white-space: nowrap;

      .icon-delete-tag {
        width: 12px;
        height: 12px;
        margin-left: 2px;
        cursor: pointer;
        fill: rgb(172, 177, 193);
      }
    }

    .icon-link-mkb-modal-open {
      position: absolute;
      right: 2px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      padding: 4px;
      background-color: $color-white;
    }
  }
}

.mkb-drop-down-list {
  position: absolute;
  overflow: auto;
  width: 100%;
  max-height: 300px;
  padding-top: 0;
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  color: $color-blue-gray-40;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;

  .ant-popover-inner-content {
    padding: 0;
  }

  .mkb-drop-down-item {
    overflow: hidden;
    height: 32px;
    padding: 5px 10px;
    cursor: pointer;

    @include flex(row, flex-start, center);

    &:hover {
      background-color: $color-gray-100;
    }
  }

  .mkb-drop-down-loader {
    @include flex(column, center, center);

    position: relative;
    top: initial;
    left: initial;
    height: 155px;
    transform: initial;

    .loader {
      padding-top: 0;
    }
  }
}
