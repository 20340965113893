@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-employees-drawer {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-header {
    padding: 16px;
  }
}
