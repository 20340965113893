@import "@src/styles/variables.scss";

.root-icon-with-area {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  user-select: none;

  &:hover {
    background-color: $color-gray-120;
  }

  &:active {
    background-color: $color-gray-100;
  }
}
