.root-copy-condition-modal {
  .ant-modal-body {
    padding-bottom: 0;
  }

  .footer {
    .copy-button {
      path {
        fill: white;
      }
    }
  }
}
