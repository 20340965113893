@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-app-tabs {
  @include flex(row, flex-start, stretch);

  height: 49px;
  padding-left: 20px;
  border-bottom: 1px solid #D5DEDF;

  .app-tabs-item {
    @include flex(row, center, center);

    position: relative;
    top: 1px;
    padding: 0 17px;
    border-top: 1px solid white;
    border-bottom: 1px solid #D5DEDF;
    color: $color-blue-gray-60;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

    &:hover {
      color: $color-blue-gray-40;
    }

    &-active {
      z-index: 1;
      padding: 0 16px;
      border: 1px solid #D5DEDF;
      border-bottom: none;
      background-color: #F6FAF7;
      border-radius: 8px 8px 0 0;
      color: $color-blue-gray-40;

      &::before {
        position: absolute;
        right: 100%;
        bottom: 0;
        width: 5px;
        height: 5px;
        border-right: 1px solid #D5DEDF;
        border-bottom: 1px solid #D5DEDF;
        border-bottom-right-radius: 8px;
        box-shadow: 2px 2px 0 #F6FAF7;
        content: "";
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 100%;
        width: 5px;
        height: 5px;
        border-bottom: 1px solid #D5DEDF;
        border-left: 1px solid #D5DEDF;
        border-radius: 0 0 0 8px;
        box-shadow: -2px 2px 0 #F6FAF7;
        content: "";
      }
    }
  }
}
