@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-patient-list {
  @include flex(column, flex-start, stretch);
  @include data-grid-no-left-borders;

  flex-grow: 1;

  .patient-list-controls {
    @include flex(row, flex-start, center);

    padding: 16px;
    background-color: white;
    color: $color-blue-gray-40;
    font-size: 12px;
    font-weight: 500;

    .patient-list-controls-tag {
      padding: 6px;
      margin-left: 8px;
      transition: opacity 200ms;

      &.loading {
        opacity: 0.5;
      }
    }

    .reset-filter-button {
      margin-right: 16px;
    }
  }
}
