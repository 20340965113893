@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-app-sidebar {
  @include flex(row, flex-start, stretch);

  position: absolute;
  z-index: 101;
  bottom: 0;
  overflow: hidden;
  width: 300px;
  max-width: 900px;
  height: 100vh;
  border-right: 1px solid #D5DEDF;
  background-color: $color-white;
  transition: width 0.2s;

  &.root-app-sidebar-nosubmenu {
    width: 300px;
  }

  &.root-app-sidebar-selected-submenu {
    width: 600px;
  }

  &.root-app-sidebar-selected-three-submenu {
    width: 900px;
  }

  .app-sidebar {
    @include flex(column, flex-start, flex-start);

    min-width: 300px;
    height: 100%;
    border-right: 1px solid #E3F1F4;

    .app-sidebar-icon-container {
      height: 49px;
      padding: 0 !important;
      border-bottom: 1px solid #D5DEDF;

      .app-sidebar-icon-container-text {
        overflow: hidden;
        margin: 0 0 0 10px;
        color: $color-blue-gray-40;
        font-size: 12px;
        font-weight: 500;
        line-height: 160%;
        white-space: nowrap;
      }

      .app-sidebar-icon {
        padding: 12px !important;
        padding-bottom: 8px !important;
        background-color: $color-primary;

        & > svg.open {
          width: 24px;
          height: 24px;
          fill: $color-white !important;
        }

        & > svg.close {
          width: 14px;
          height: 14px;
          margin: 5px;
          fill: $color-white !important;
        }

        &:hover {
          background-color: $color-primary !important;
        }
      }
    }

    .app-sidebar-input-container {
      display: flex;
      width: 100%;
      padding-top: 16px;
      padding-left: 16px;
      border-top: 1px solid #D5DEDF;
      margin-top: 14px;
      opacity: 1;
      transition: opacity 0.2s ease;

      .app-sidebar-input-container-search {
        position: relative;
        top: 8px;
        right: 25px;
        height: 16px;
        padding-left: 5px;
      }

      .app-sidebar-input-container-input {
        width: 95%;
      }
    }

    .app-sidebar-copyright {
      @include flex(column, flex-start, stretch);

      align-self: stretch;
      margin-top: auto;

      &-copy {
        padding: 0  0 4px 8px;
        border-bottom: 1px solid #D5DEDF;
        color: $color-blue-gray-60;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;

        p {
          margin-top: 0;
        }
      }

      &-ver {
        color: $color-grey;
        font-size: 9px;
      }
    }
  }

  &.root-app-sidebar-collapsed {
    width: 48px;

    .app-sidebar .app-sidebar-input-container {
      opacity: 0;
      pointer-events: none;
    }
  }

  .app-sidebar-submenu {
    min-width: 300px;
    height: auto;
    border-top: 1px solid rgba(189, 206, 219, 0.6);
    margin-top: 48px;
    background-color: $color-white;
    overflow-y: auto;

    &.third-menu {
      border-left: 1px solid #E3F1F4;
    }
  }
}

.app-sidebar-shadow {
  position: absolute;
  z-index: 100;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #2C3D43;
  opacity: 0.35;
  transition: opacity 0.2s;

  &.app-sidebar-shadow-hidden {
    opacity: 0;
    pointer-events: none;
  }
}
