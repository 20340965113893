.root-region-route-status-modal {
  .ant-modal-body {
    padding: 0;
  }

  .region-route-status-modal {
    height: 500px;
    max-height: calc(100vh - 120px);

    .region-route-status-modal-document {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .region-route-status-modal-button {
    max-width: max-content;
    margin-left: auto;
  }
}
