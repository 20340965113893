@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-show-ext-history-modal {
  .ant-drawer-body {
    padding: 0;
  }

  .show-ext-history-modal-header {
    position: relative;
    padding: 19px 16px 16px;

    .show-ext-history-modal-close-icon {
      position: absolute;
      top: 19px;
      right: 16px;
      fill: $color-blue-gray-40;
    }

    .show-ext-history-modal-title {
      color: #2C3034;
      font-size: 18px;
      font-weight: 500 !important;
      line-height: 160%;
    }
  }

  .show-ext-history-modal-content {
    @include flex(column, flex-start, stretch);

    padding: 16px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    tr {
      cursor: pointer;

      &:nth-child(even) {
        background: #FAFBFA;
      }

      th {
        padding: 9px;
        border: 1px solid #D5DEDF;
        background: #FAFBFA;
        color: $color-blue-gray-40;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
        word-break: break-all;

        &:first-of-type {
          padding-left: 16px;
        }
      }

      th:first-child {
        border-left: 0;
      }

      td {
        overflow: hidden;
        padding: 12px;
        border: 1px solid #D5DEDF;
        color: #19282B;
        font-size: 13px;
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-of-type {
          padding-left: 16px;
        }

        .show-ext-item-type {
          @include flex(row, flex-start, center);

          svg {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
        }
      }

      td:first-child {
        border-left: 0;
      }
    }
  }
}
