@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-pagination {
  @include flex(row, flex-start, center);

  width: 100%;
  height: 48px;
  border-top: 1px solid $color-gray-80;
  border-bottom: 1px solid $color-gray-80;
  background-color: $color-white;
  color: $color-blue-gray-40;
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;

  .pagination-select {
    min-width: 66px;
  }

  .pagination-select * {
    color: $color-blue-gray-40;
    font-size: 12px;
    font-weight: 500;
  }

  .pagination-numbers {
    @include flex(row, space-between, center);

    flex: 1;

    .pagination-numbers-arrow {
      width: 5px;
      height: 10px;
    }
  }

  .pagination-show {
    @include flex(row, flex-start, center);

    height: 48px;
    padding-right: 15px;
    padding-left: 20px;
    border-left: 1px solid $color-gray-80;
  }
}
