@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-table {
  min-height: 82vh;
  border: 1px solid $color-gray-80;
  margin: 16px;
  border-radius: 8px;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    thead {
      tr {
        background-color: $color-gray-120;
      }

      th {
        padding: 10px !important;
        border-top: 0;
        border-right: 1px solid $color-gray-80;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: $color-blue-gray-60;
        font-size: 12px;
        font-weight: 500;

        &:last-child {
          border-right: 0;
        }
      }
    }

    tr {
      background-color: white;
      color: #19282B;
      cursor: pointer;

      &:nth-child(even) {
        background-color: $color-gray-120;
      }

      &:hover {
        background-color: #F7F7F7;
      }

      th {
        padding: 8px;
        font-size: 12px;
        text-align: left;
      }

      td {
        padding: 8px;
        border: 1px solid $color-gray-80;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        color: inherit;
        font-size: 13px;
        word-break: break-all;

        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }

    .table-action {
      max-width: 80%;
      padding-top: 2px;
      color: $color-green-80;
      cursor: pointer;
      font-size: 13px;
      font-style: normal;
      font-weight: normal;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}
