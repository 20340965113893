@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-meth-reg-conditions-page {
  @include flex(row, flex-start, flex-start);

  flex-grow: 1;
  padding: 16px;
  border-top: 1px solid #D5DEDF;

  .meth-reg-conditions-page-cancel {
    position: absolute;
    top: 4px;
    right: 8px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.1s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.stage-title-badge {
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  background: #56777F;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
