@import "@src/styles/variables.scss";

.root-linear-progress {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 4px;
  opacity: 0;
  opacity: 1;
  transform: translateZ(0);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.6, 1);

  &.hidden {
    opacity: 0;
  }

  .bar {
    position: absolute;
    width: 100%;
    height: 100%;

    .bar-inner {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: $color-primary;
    }

    &.primary {
      left: -145%;
      animation: primary-translate 2s infinite linear;

      > .bar-inner {
        animation: primary-scale 2s infinite linear;
      }
    }

    &.secondary {
      left: -54%;
      animation: secondary-translate 2s infinite linear;

      > .bar-inner {
        animation: secondary-scale 2s infinite linear;
      }
    }
  }
}

@keyframes primary-translate {
  0% {
    transform: translateX(0);
  }

  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.7, 0.5);
    transform: translateX(0);
  }

  59.15% {
    animation-timing-function: cubic-bezier(0.3, 0.38, 0.55, 0.95);
    transform: translateX(83%);
  }

  100% {
    transform: translateX(200%);
  }
}

@keyframes primary-scale {
  0% {
    transform: scaleX(0.08);
  }

  36.65% {
    animation-timing-function: cubic-bezier(0.33, 0.125, 0.78, 1);
    transform: scaleX(0.08);
  }

  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.66);
  }

  100% {
    transform: scaleX(0.08);
  }
}

@keyframes secondary-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.51, 0.41);
    transform: translateX(0);
  }

  25% {
    animation-timing-function: cubic-bezier(0.31, 0.28, 0.8, 0.73);
    transform: translateX(37.6%);
  }

  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.63, 0.6, 0.9);
    transform: translateX(84.3%);
  }

  100% {
    transform: translateX(160.2%);
  }
}

@keyframes secondary-scale {
  0% {
    animation-timing-function: cubic-bezier(0.2, 0.06, 0.57, 0.45);
    transform: scaleX(0.08);
  }

  19.15% {
    animation-timing-function: cubic-bezier(0.15, 0.196, 0.648, 1.004);
    transform: scaleX(0.45);
  }

  44.15% {
    animation-timing-function: cubic-bezier(0.257, -0.003, 0.211, 1.38);
    transform: scaleX(0.73);
  }

  100% {
    transform: scaleX(0.08);
  }
}
