@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-button {
  @include flex(row, center, center);

  position: relative;
  min-width: min-content;
  min-height: 32px;
  padding: 0 12px;
  border: 1px solid #ABC0C2;
  background-color: white;
  border-radius: 16px;
  color: $color-blue-gray-40;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  opacity: 1;
  transition: background-color 0.2s, border 0.3s, opacity 0.2s;
  user-select: none;

  &.corners-right {
    border-radius: 16px 0 0 16px;
  }

  &.corners-left {
    border-radius: 0 16px 16px 0;
  }

  .root-buttom-loader {
    overflow: hidden;
    padding: 0;

    .loader {
      width: 30px;
    }
  }

  .button-icon {
    max-width: 16px;
    max-height: 16px;
  }

  .button-icon-left {
    max-width: 16px;
    max-height: 16px;
    margin-right: 8px;
  }

  .button-icon-right {
    max-width: 16px;
    max-height: 16px;
    margin-left: 8px;
  }

  .button-label {
    font-size: 13px;
    font-weight: 500;
    line-height: 100%;
    white-space: nowrap;
  }

  .button-counter {
    @include flex(row, center, center);

    width: 18px;
    height: 18px;
    margin-left: 8px;
    background-color: $color-blue-gray-80;
    border-radius: 50%;
    color: $color-white;
    font-size: 12px;
  }

  &:hover {
    border: 1px solid $color-primary;
    background: $color-white;
  }

  &:active {
    border-color: $color-blue-gray-40;
    background-color: $color-gray-120;
  }

  &.disabled {
    border: 1px solid #D5DEDF;
    background: #ECF2F0;
    color: #ABC0C2;
    cursor: default;
    opacity: 0.3;

    & > svg {
      cursor: default !important;
    }

    &:hover {
      border: 1px solid #D5DEDF;
      background: #ECF2F0;
      color: #ABC0C2;
    }

    &:active {
      border: 1px solid #D5DEDF;
      background: #ECF2F0;
      color: #ABC0C2;
    }
  }

  &.loading {
    justify-content: flex-start;
    border-color: rgba(0, 0, 0, 0.12) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    opacity: 0.8 !important;
  }

  /**
 * Red button
*/
  &.secondary {
    border: 1px solid #840300;
    background: #FFF;
    color: #840300;

    &:hover {
      border: 1px solid #B12B28;
      background-color: white;
      color: #B12B28;
    }

    &:active {
      border: 1px solid #840300;
      background: rgba(132, 3, 0, 0.05);
      color: #840300;
    }

    &.disabled {
      border: 1px solid #840300;
      background: #FFF;
      color: #840300;
      cursor: default;
      opacity: 0.3;

      & > svg {
        cursor: default !important;
      }

      &:hover {
        border: 1px solid #840300;
        background: #FFF;
        color: #840300;
        opacity: 0.3;
      }

      &:active {
        border: 1px solid #840300;
        background: #FFF;
        color: #840300;
        opacity: 0.3;
      }
    }
  }

  /**
 * Green button
*/
  &.success {
    border: 1px solid #009866;
    background: #0EBE89;
    color: #FFF;

    &:hover {
      border: 1px solid #009866;
      background: #17D39B;
      color: #FFF;
    }

    &:active {
      border: 1px solid #009866;
      background: #009866;
      color: #FFF;
    }

    &.disabled {
      border: 1px solid #009866;
      background: #0EBE89;
      color: #FFF;
      cursor: default;
      opacity: 0.3;

      & > svg {
        cursor: default !important;
      }

      &:hover {
        border: 1px solid #009866;
        background: #0EBE89;
        color: #FFF;
        opacity: 0.3;
      }

      &:active {
        border: 1px solid #009866;
        background: #0EBE89;
        color: #FFF;
        opacity: 0.3;
      }
    }
  }
}

.icon-button {
  padding: 0 8px;
  border: 1px solid $color-gray-100;
  border-radius: 8px;
}

.icon-small {
  .button-icon {
    max-width: 10px;
    max-height: 10px;
  }
}

.button-loading-text {
  padding-right: 30px;
  color: rgba(0, 0, 0, 0.26);
}
