@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-import-modal {
  .import-modal {
    @include flex(column, flex-start, stretch);

    height: 700px;
    max-height: calc(100vh - 180px);

    .dict-import-cell {
      @include flex(row, center, center);
    }

    .cell-grey {
      color: $color-gray-60;
    }
  }

  .modal-footer {
    @include flex(row, flex-start, center);
  }
}
