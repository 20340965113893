@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-regional-conditions-drawer {
  .condition-block {
    &:not(:last-child) {
      border-bottom: 1px solid #E3F1F4;
      margin-bottom: 16px;
    }
  }
}

.root-block-card {
  overflow: hidden;
  border: 1px solid var(--grey-80, #D5DEDF);
  margin-bottom: 16px;
  border-radius: 8px;

  .block-card-titles {
    display: flex;
    border-bottom: 1px solid var(--grey-80, #D5DEDF);

    .block-card-title-column {
      flex: 1;
      padding: 12px 16px;

      &:not(:last-child) {
        border-right: 1px solid var(--grey-80, #D5DEDF);
      }
    }

    background-color: #FAFBFA;
    color: var(--blue-grey-60, #36575F);
    font-size: 12px;
    font-weight: 500;
  }

  .block-card-content {
    display: flex;
    color: var(--blue-grey-40, #1C3D45);
    font-size: 14px;
    font-weight: 400;
    gap: 0;
    grid-auto-columns: 1fr;

    .block-card-content-column {
      @include flex(column, flex-start, flex-start);

      overflow: hidden;
      flex: 1;
      flex-wrap: nowrap;
      padding: 12px 16px;

      &:not(:last-child) {
        border-right: 1px solid var(--grey-80, #D5DEDF);
      }

      span {
        max-width: 100%;
        word-wrap: break-word;

        &.right {
          color: var(--green-60, #007445);
        }

        &.wrong {
          color: var(--orange-60, #AC320C);
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
