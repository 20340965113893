@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-app-nav {
  width: 100%;
  background-color: $color-white;
  font-size: 12px;

  .app-nav-title {
    display: flex;
    min-height: 41px;
    align-items: center;
    padding: 16px;
    padding-top: 0;
    color: #32343F;
    font-size: 21px;
    font-weight: 500;
    line-height: 100%;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .app-nav-tag {
      margin: 0 5px;
    }
  }
}
