@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-add-ext-modal {
  .ant-drawer-body {
    padding: 0;
  }

  .add-ext-modal-content {
    @include flex(column, flex-start, stretch);

    padding: 16px;

    &-field {
      @include flex(column, flex-start, stretch);

      margin-top: 16px;
      margin-bottom: 8px;

      &-title {
        margin-bottom: 2px;
        color: $color-blue-gray-60;
        font-size: 12px;
        font-weight: 500 !important;
        line-height: 160%;
      }

      &-input {
        margin-top: 8px;
      }
    }

    &-cb {
      @include flex(row, flex-start, center);

      margin-top: 16px;

      &-title {
        width: 100%;
        margin-left: 4px;
        color: $color-blue-gray-60;
        font-size: 12px;
      }
    }

    .add-ext-modal-switch {
      margin-left: -7px;
    }

    .input-error {
      border: 1px solid red;
    }
  }

  .add-ext-modal-error {
    padding: 0 16px 8px;
    color: $color-red;
    font-size: 12px;
  }

  .add-ext-modal-actions {
    @include flex(row, flex-end, stretch);
  }
}
