@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-mkb-select-modal {
  .mkb-select-modal-content {
    @include flex(column, flex-start, stretch);

    height: 100%;

    .mkb-select-modal-tree {
      ul, li {
        list-style-type: none;
      }

      ul {
        padding-left: 30px;

        &.tree-root {
          padding-left: 0;
          margin-bottom: 0;
        }
      }

      .mkb-select-modal-mkb {
        @include flex(row, flex-start, center);

        padding: 4px 8px 6px 0;
        font-size: 14px;

        .mkb-select-modal-mkb-title {
          overflow: hidden;
          max-width: 900px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .mkb-select-modal-mkb-checkbox {
          margin: 0 12px;
        }

        .expand {
          @include flex(row, center, center);

          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          border-radius: 50%;
          color: $color-grey;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          transition: background-color 0.1s;
          user-select: none;

          &.placeholder {
            color: transparent;
          }

          &:hover:not(.placeholder) {
            background-color: $color-gray-100;
          }

          &:active:not(.placeholder) {
            background-color: $color-gray-80;
          }
        }
      }
    }
  }

  .mkb-select-modal-actions {
    @include flex(row, flex-end, stretch);
  }
}
