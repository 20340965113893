@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-accordion {
  @include flex(column, flex-start, stretch);

  width: 100%;
  border: 1px solid $color-gray-80;
  background-color: $color-gray-120;
  border-radius: 8px;
  cursor: pointer;

  .icon-wrapper-title {
    margin-top: 16px;
    margin-right: 16px;
    margin-bottom: auto;
  }

  .root-accordion-title-container {
    @include flex(row, space-between, center);

    .root-accordion-title-container-data-container {
      @include flex(row, space-between, center);
    }

    %container-title {
      overflow: hidden;
      max-width: 85%;
      text-overflow: ellipsis;
    }

    .accordion-title-container-title {
      @extend %container-title;
    }

    width: 100%;
    height: 64px;
    padding: 0 16px;
    color: $color-blue-gray-40;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &.clinical-recommendation-title {
      height: 87px;
      font-weight: 400;
      white-space: normal;

      .clinical-recommendation-title-description {
        margin-top: 12px;
        color: $color-blue-gray-100;
        font-size: 12px;
        font-weight: 500;
      }

      .count {
        color: $color-blue-gray-40;
      }
    }

    > span {
      margin-right: 40px;
    }

    .accordion-title-container-index-board {
      display: inline-flex;
      width: 90%;
      align-items: center;

      .accordion-title-container-index-board-index {
        display: inline-flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        padding-top: 1px;
        margin-right: 8px;
        background: #56777F;
        border-radius: 50%;
        color: white;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
      }

      .accordion-title-container-index-board-title {
        @extend %container-title;
      }
    }
  }

  .root-accordion-title-data-container {
    @include flex(row, flex-end, center);

    width: 100%;
  }

  .clinical-recommendation-title-data-container {
    width: 125px;
  }

  .root-accordion-icon {
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
    transition: transform 200ms ease;

    &.icon-collapsed {
      transform: rotate(270deg);
    }
  }

  .root-accordion-data {
    @include flex(column, center, left);

    padding: 16px;
    border-top: 1px solid $color-gray-80;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: $color-blue-gray-40;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
  }

  .root-accordion-data-collapsed {
    display: none;
  }
}

.blue-gray {
  border: 1px solid #99BCC5;
  background-color: #F2FFFD;
}

.root-clinical-recommendation {
  height: 87px;
  background-color: $color-white;
}

.root-accordion-collapsed {
  display: block;
  height: auto;
}
