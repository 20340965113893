@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";
@import "@src/styles/utilities/index.scss";

.root-data-grid {
  @include flex(column, flex-start, stretch);

  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 155px);
  flex-grow: 1;
  border-right: 1px solid #D5DEDF;
  overflow-x: auto !important;

  .data-grid-loader {
    position: absolute;
  }

  .header-ponytail {
    border-left: 1px solid $color-gray-80;
  }

  &.data-grid-pagination {
    height: calc(100vh - 156px);
  }

  &.grid-table-scrollbar {
    .grid-table-body {
      .grid-table-body-row:last-child {
        border-bottom: 0;
      }
    }
  }

  .grid-table-header {
    display: flex;
    border: 1px solid $color-gray-80;
    border-right: 0;
    border-left: 0;
    background-color: $color-gray-120;

    .grid-table-header-checkbox-state-minus {
      input[type="checkbox"]::after {
        top: -3px;
        left: 3px;
        content: "\2501";
        transform: none;
      }
    }

    .grid-table-header-row {
      display: grid;
      width: 100%;
      grid-gap: 0;
      grid-template-rows: repeat(1, auto);

      &.row-selectable {
        grid-template-columns: 35px repeat(50, 1fr);
      }

      .grid-table-header-col {
        @include flex(column, flex-start, stretch);

        overflow: hidden;
        width: 100%;
        max-width: 100%;
        flex-grow: 1;
        padding: 8px;
        border-right: 1px solid $color-gray-80;
        color: $color-blue-gray-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:nth-last-child(-n+2) {
          border-right: 0;
        }

        &:first-child {
          border-left: 0;
        }

        .grid-table-header-col-title {
          @include flex(row, space-between, flex-start);

          margin-bottom: 4px;

          .grid-table-header-col-title-text {
            overflow: hidden;
            max-width: 95%;
            text-overflow: ellipsis;
          }

          .grid-table-header-col-icons {
            height: 22px;
            margin-left: auto;

            & > * {
              width: 22px;
              min-width: 22px;
              height: 22px;
              min-height: 22px;
              padding: 3px;
              border-radius: 50%;
              cursor: pointer;
              transition: background-color 0.1s;

              &.grid-table-header-col-icon-null {
                padding: 4px;
                color: #7799A1;
              }

              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }
    }
  }

  .grid-table-body {
    max-height: calc(100vh - 100px);
    color: #19282B;
    overflow-x: hidden !important;

    .grid-table-body-row {
      display: grid;
      width: 100%;
      border-bottom: 1px solid $color-gray-80;
      background-color: #FFF;
      grid-gap: var(--gap);
      grid-template-rows: repeat(1, auto);

      &.row-selectable {
        grid-template-columns: 35px repeat(50, 1fr);
      }

      &.grid-even {
        background: $color-gray-120;
      }

      &:hover {
        background-color: #F7F7F7;
        cursor: pointer;
      }

      .grid-table-body-col {
        @include flex(row, flex-start, center);

        width: 100%;
        max-width: 100%;
        height: 100%;
        flex-grow: 1;
        padding: 4px 8px;
        border-right: 1px solid $color-gray-80;
        margin-top: auto;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;

        .col-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:nth-last-child(-n+1) {
          border-right: 0;
        }
      }
    }
  }
}
