.root-territory-mo-modal {
  .territory-mo-modal-body {
    .territory-mo-modal-body-block {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .territory-mo-modal-icon {
        display: contents;

        .root-icon {
          width: 18px;
          height: 18px;
          margin-left: 4px;
        }
      }

      .territory-mo-modal-body-block-title {
        color: #7B868C;
        font-size: 12px;
        font-weight: 500;
      }

      .territory-mo-modal-body-sub-block {
        display: flex;
        width: 100%;
        flex-direction: column;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .territory-mo-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}
