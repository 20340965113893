@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-services-modal {
  .ant-modal-body {
    padding: 0;
  }

  .services-modal-services {
    @include flex(column, flex-start, stretch);

    height: 500px;
    overflow-y: scroll;

    .services-modal-service {
      @include flex(column, flex-start, flex-start);

      padding: 16px 16px 0;
      border: 1px solid #E3F1F4;
      margin: 8px 16px;
      border-radius: 8px;

      .services-modal-service-block {
        @include flex(column, flex-start, flex-start);

        margin-bottom: 16px;

        .services-modal-service-block-title {
          color: #ACB1C1;
          font-size: 12px;
          font-weight: 500;
          line-height: 150%;
        }

        .services-modal-service-block-data {
          color: $color-black-light;
          font-size: 14px;
          line-height: 150%;
        }
      }
    }
  }
}
