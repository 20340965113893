@mixin grid ($columns, $gap) {
  @for $i from 1 through $columns {
    .grid-#{$i} {
      display: grid;
      width: 100%;
      grid-gap: $gap;
      grid-template-columns: repeat($i, 1fr);
      grid-template-rows: repeat(1, auto);
    }

    .x#{$i} {
      grid-column-start: span $i;
    }
  }
}

@for $i from 0 through 500 {
  .grid-row-start-#{$i} {
    grid-row-start: span $i;
  }
  .grid-column-start-#{$i} {
    grid-column-start: span $i;
  }
}

@for $i from 0 through 50 {
  .grid-#{$i} {
    display: grid;
    width: 100%;
    grid-gap: var(--gap);
    grid-template-columns: repeat($i, 1fr);
    grid-template-rows: repeat(1, auto);
  }

  .x#{$i} {
    grid-column-start: span $i;
  }

  @media screen and (max-width: 1500px) {
    .xlg#{$i} {
      grid-column-start: span $i;
    }
  }

  @media screen and (max-width: 980px) {
    .md#{$i} {
      grid-column-start: span $i;
    }
  }

  .grid-gap-#{$i} {
    grid-gap: #{$i}px !important;
  }
}

.grid-1920 {
  display: grid;
  width: 100%;
  grid-gap: 0;
  grid-template-columns: repeat(1920, 1fr);
  grid-template-rows: repeat(1, auto);
}
