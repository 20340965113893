@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-user-role-select {
  width: 340px;
  color: $color-black-light;

  .role-select-header {
    @include flex(row, flex-start, flex-start);

    padding-bottom: 8px;
    border-bottom: 1px solid #E3F1F4;
    margin-bottom: 16px;

    .role-select-header-title {
      font-size: 16px;
      font-weight: 500;
      word-break: break-all;
    }

    .role-select-header-close {
      fill: rgb(172, 177, 193);
    }
  }

  .role-select-row {
    @include flex(column, flex-start, flex-start);

    margin-top: 16px;

    .role-select-row-title {
      color: $color-grey;
      font-size: 12px;
    }

    .role-select-row-data {
      margin-top: 8px;
      color: $color-black-light;
      font-size: 14px;
    }
  }
}
