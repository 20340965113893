@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-npa-data-page {
  @include flex(column, flex-start, stretch);

  flex-grow: 1;

  .ant-tabs {
    flex-grow: 1;

    .ant-tabs-content {
      @include flex(column, flex-start, stretch);

      width: 100%;
      height: 100%;
    }
  }

  .npa-data-title {
    @include flex(row, flex-start, center);

    div {
      margin-left: 10px;
    }
  }
}
