@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-nsi-frnsi-page {
  @include flex(column, flex-start, stretch);
  @include data-grid-no-left-borders;

  height: 100%;

  .frnsi-controls {
    @include flex(row, flex-start, center);

    min-height: 60px;
    padding: 0 16px 16px;
    background-color: $color-white;

    .list-counter {
      @include flex(row, flex-start, center);

      font-weight: 500;
      transition: opacity 200ms ease;

      &.loading {
        opacity: 0.5;
      }

      & > span {
        white-space: nowrap;
      }
    }
  }

  .cell-align-end {
    & > * {
      padding-right: 4px;
      margin-left: auto;
    }
  }
}
