@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-tab-route {
  height: 100%;
  background: url("~src/assets/icons/dot.svg");
  background-position: 9px 9px;

  .route-tab-wraper {
    @include flex(column, flex-start, stretch);

    height: calc(100vh - 138px);

    .wrapper-top {
      @include flex(row, space-between, center);

      padding: 16px 12px;
      border: 1px solid #D5DEDF;
      margin: 16px;
      background-color: white;
      border-radius: 8px;

      .wrapper-top-left {
        @include flex(row, flex-start, stretch);

        .wrapper-top-section {
          @include flex(column, flex-start, flex-start);

          .wrapper-top-left-title {
            margin-bottom: 4px;
            color: $color-blue-gray-60;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }

          .route-tab-select {
            width: 600px;
            min-width: 200px;
            height: 32px;

            .select-search-long-main-input {
              width: inherit;
              min-width: inherit;
              border-radius: 8px;

              .root-text-input {
                border-radius: 8px;

                .text-input-input {
                  border-radius: 8px;
                }
              }
            }
          }
        }
      }

      .wrapper-top-button-wrapper {
        @include flex(column, flex-end, flex-start);

        height: 55px;
        overflow-x: hidden;
      }
    }

    .wrapper-route {
      overflow: scroll;
      margin: 0 16px;
      background-color: white;

      .patient-routes {
        padding-top: 0;
      }
    }

    .not-selected {
      @include flex(column, flex-start, center);

      margin-top: 60px;
      color: $color-blue-gray-80;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
