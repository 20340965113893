@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-route-description-tab {
  @include flex(row, space-between, stretch);

  margin: 16px;
  margin-right: 0;
  color: $color-blue-gray-40;
  font-style: 14px;
  font-weight: 400;
  line-height: 21px;

  .route-description-tab-navigation-menu {
    max-width: 328px;
    height: calc(100vh - 170px);
    border: 1px solid #D5DEDF;
    background-color: white;
    border-radius: 8px;

    &::-webkit-scrollbar {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    .route-description-tab-navigation-menu-container {
      .route-description-tab-navigation-menu-item {
        @include flex(column, center, stretch);

        min-height: 37px;
        padding: 0 20px;
        margin: 8px 0;
        cursor: pointer;
      }

      .route-description-tab-navigation-menu-item:hover {
        background-color: $color-gray-100;
      }

      .route-description-tab-navigation-menu-item-header {
        width: 100%;
        height: 56px;
        border-top: 1px solid #D5DEDF;
        border-bottom: 1px solid #D5DEDF;
        font-weight: 500;
      }

      &:last-child .route-description-tab-navigation-menu-item-header {
        border-top: 1px solid #D5DEDF;
        border-bottom: 0;
        margin-bottom: 0;
      }

      &:first-child .route-description-tab-navigation-menu-item-header {
        border-top: 0;
        border-bottom: 1px solid #D5DEDF;
        margin-top: 0;
      }
    }
  }

  .route-description-tab-content {
    @include flex(column, flex-start, center);

    overflow: auto;
    width: 100%;
    height: calc(100vh - 170px);
    padding-right: 16px;

    .route-description-tab-content-accordion {
      margin-bottom: 17px;

      &.root-accordion .root-accordion-title-container {
        height: 56px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .route-description-tab-content-icon {
        width: 22px;
        min-width: 22px;
        height: 22px;
        min-height: 22px;
        padding: 3px;
        border-radius: 50%;
        transition: background-color 0.1s;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      .route-description-tab-content-location-icon {
        width: 24px;
        height: 24px;
        padding: 2px 4px;
        background: $color-gray-60;
        border-radius: 12px;
      }

      .route-description-tab-content-tag-title {
        margin-bottom: 8px;
        color: $color-blue-gray-40;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        &:last-of-type {
          margin-top: 24px;
        }

        &:first-of-type {
          margin-top: 0;
        }
      }

      .route-description-tab-content-tag-container {
        @include flex(row, left, center);

        margin-bottom: 5px;

        span {
          margin-left: 8px;
        }
      }
    }
  }
}
