@font-face {
  font-family: Rubik;
  src: local("Rubik"), url("./assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Rubik;
  font-weight: 500;
  src: local("Rubik"), url("./assets/fonts/Rubik/Rubik-Medium.ttf") format("truetype");
}

#root {
  width: 100%;
  height: 100%;
  background-color: #F6FAF7;
}

* {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  text-decoration-skip: objects;
  transition: color 0.3s;
}

a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

a:visited, a:hover, a:active, {
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  overflow: auto;
  margin-top: 0;
  margin-bottom: 1em;
}

p {
  margin: 0;
}

/* Дефолтные настройки стилей тегов из Figma */

h2 {
  color: var(--blue-grey-40, #1C3D45);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
