@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-patient-card {
  @include flex(column, flex-start, stretch);

  flex-grow: 1;

  .ant-tabs {
    flex-grow: 1;

    .ant-tabs-content {
      @include flex(column, flex-start, stretch);

      width: 100%;
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
}
