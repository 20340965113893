@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-meth-routes-table {
  @include flex(column, flex-start, stretch);

  height: 100%;

  .root-data-grid {
    max-height: calc(100vh - 230px);
    border-left: 1px solid #D5DEDF;
  }

  .list-container {
    @include flex(column, flex-start, stretch);

    height: 100%;
    padding: 16px;

    .list-controls {
      @include flex(row, flex-start, center);

      padding: 16px;
      border-top: 1px solid $color-gray-80;
      border-right: 1px solid $color-gray-80;
      border-left: 1px solid $color-gray-80;
      background-color: $color-white;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;

      .list-counter {
        @include flex(row, flex-start, center);

        font-weight: 500;
        transition: opacity 200ms ease;

        &.loading {
          opacity: 0.5;
        }

        & > span {
          white-space: nowrap;
        }
      }
    }
  }
}
