@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-date-picker {
  &.ant-picker {
    height: 32px;

    &.ant-picker-focused {
      box-shadow: 0 0 3px #D5DEDF;
    }

    input {
      height: 22px;
    }
  }
}
