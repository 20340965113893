@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-action-menu-overlay {
  .ant-popover-content {
    .ant-popover-inner {
      @include dropdown-shadow;

      border-radius: 10px;

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }

  .action-menu-options {
    @include flex(column, flex-start, stretch);

    .menu-option {
      @include flex(row, flex-start, center);

      height: 42px;
      padding-left: 20px;
      color: $color-blue-gray-40;
      cursor: pointer;
      transition: background-color 0.1s;

      &:hover {
        background-color: $color-gray-100;
      }

      &:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
