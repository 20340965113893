@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-journal-semd-list-page {
  @include flex(column, flex-start, stretch);

  height: 100%;

  .list-controls {
    @include flex(row, space-between, stretch);

    padding: 16px;
    background-color: white;
  }

  .journal-semd-grid {
    .grid-table-header {
      border-left: 0;
    }

    .grid-table-body-row {
      border-left: 0;
    }
  }
}
