// stylelint-disable

:root, :host {
  --gap: 16px;
  --halfContainer: calc((100vw - 32px) / 2);
  --column: calc((2 * var(--halfContainer) - 11 * var(--gap)) / 12);
  --btnHeight: 49px;
  --columnGap: calc(var(--column) + var(--gap));
  --addPadd: var(--columnGap);
}
