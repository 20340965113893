@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.sidebar-submenu-entry {
  @include flex(row, flex-start, stretch);

  width: 100%;
  min-height: 63px;
  padding: 16px;
  cursor: pointer;

  &:first-child {
    height: 49px;
    border-bottom: 1px solid #D5DEDF;
  }

  &.active {
    .sidebar-submenu-label {
      color: $color-green-80;
    }
  }

  &:hover {
    background-color: #F6FAF7;
  }

  &:first-child:hover {
    background-color: white;
  }

  .sidebar-submenu-label {
    @include flex(row, space-between, center);

    width: 100%;
    color: #36575F;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
  }
}
