@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-list-drawer {
  padding: 12px;

  .list-drawer-row {

  }

  .row-delete-icon {
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $color-gray-120;
    }

    &:active {
      background-color: $color-gray-100;
    }
  }
}
