@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-dict-content-tab {
  position: relative;

  .dict-content-tab-content-view {
    @include flex(column, flex-start, stretch);

    .dict-content-tab-actions {
      @include flex(row, flex-start, flex-end);

      padding: 20px 15px;
      color: $color-blue-gray-60;

      & > * {
        width: 120px;
        margin-right: 8px;
      }

      label {
        margin-right: 5px;
        font-size: 12px;
      }

      div {
        @include flex(row, flex-start, center);
      }
    }

    table {
      width: 100%;
      background-color: #FFF;
      border-collapse: collapse;
      table-layout: fixed;

      tr {
        color: $color-black-light;
        cursor: pointer;

        th {
          padding: 8px;
          border: 1px solid #D5DEDF;
          background: #FAFBFA;
          color: $color-blue-gray-60;
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          word-break: break-all;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        td {
          padding: 8px;
          border: 1px solid #D5DEDF;
          color: inherit;
          font-size: 13px;
          word-break: break-all;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }

      .row-1 {
        background: #FAFBFA;
      }

      .table-scroll-loader {
        position: relative;
      }

      .root-dict-content-tab-loader {
        .loader {
          padding: 0;
        }
      }
    }
  }

  .loading-error {
    color: $color-red;
  }
}
