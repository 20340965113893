@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-journal-audit-page {
  @include flex(column, flex-start, stretch);

  height: 100%;

  .audit-controls {
    @include flex(row, flex-start, flex-end);

    min-height: 78px;
    padding: 0 16px 16px;
    background-color: $color-white;

    .control-block {
      @include flex(column, flex-start, flex-start);

      & > label {
        padding-bottom: 4px;
        color: $color-blue-gray-60;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .syscall-switch {
      @include flex(row, flex-start, center);
    }

    .list-counter {
      @include flex(row, flex-start, center);

      font-weight: 500;
      transition: opacity 200ms ease;

      &.loading {
        opacity: 0.5;
      }

      & > span {
        white-space: nowrap;
      }
    }

    .controls-actions {
      @include flex(row, flex-start, center);
    }
  }

  .audit-grid {
    .grid-table-header {
      border-left: 0;
    }

    .grid-table-body-row {
      border-left: 0;
    }
  }
}

.journal-audit-message-cell {
  @include flex(row, flex-start, center);

  color: $color-primary;
  text-decoration: underline;
}
