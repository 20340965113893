@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.about-page-root {
  padding: 16px;
  color: $color-black-light;
  font-size: 12px;

  .row {
    @include flex(row, flex-start, center);

    div:first-child {
      width: 200px;
    }
  }

  .error {
    color: $color-red;
  }

  .header {
    color: black;
    font-size: 14px;
  }
}
