@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-resources {
  background-color: white;

  .admin-resources-loader-wrapper {
    @include flex(row, center, center);

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 48px - 37px);
    background-color: rgba(0, 0, 0, 0.1);
  }

  .resources-action {
    padding: 15px;
  }

  .input-label {
    margin-bottom: 10px;
    color: $color-blue-gray-60;
    font-size: 12px;
    font-weight: 500;
  }

  .add-filtered-input {
    width: 300px;
  }

  table {
    width: 100%;
    height: max-content;
    background-color: white;
    border-collapse: collapse;
    table-layout: fixed;

    tr {
      &:nth-child(even) {
        background: #FAFBFA;
      }

      &:hover {
        td {
          background-color: #F7F7F7;
          cursor: pointer;

          &:first-child {
            border-bottom-left-radius: 18px;
          }

          &:last-child {
            border-bottom-right-radius: 18px;
          }
        }
      }
    }

    th, td {
      border: 1px solid #D5DEDF;
      border-right: 0;
      border-bottom: 0;
      text-align: left;

      &:first-child {
        border-left: 0;
      }
    }

    th {
      height: 40px;
      padding: 8px;
      background-color: $color-gray-120;
      color: $color-blue-gray-40;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
    }

    td {
      overflow: hidden;
      padding: 12px 8px;
      color: #19282B;
      font-size: 13px;
      font-weight: normal;
      line-height: 130%;
      text-overflow: ellipsis;
    }
  }
}
