@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-tag-component {
  @include flex(row, flex-start, center);

  width: max-content;
  max-width: 100%;
  min-height: 25px;
  padding: 2px 5px;
  background: #E0F6E5;
  border-radius: 5px;
  color: $color-blue-gray-40;
  cursor: pointer;
  font-size: 13px;
  white-space: nowrap;

  & > p {
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
  }
}

.dusty-blue {
  background-color: #E0F6EF;
}

.lavender {
  background-color: #E0ECF6;
}

.light-lavender {
  background-color: #E8E0F6;
}

.krayola-thistle {
  background-color: #F6E0EE;
}

.almond-craiola {
  background-color: #F6E0E0;
}

.beige {
  background-color: #F6EFE0;
}

.light-beige {
  background-color: #F3F6E0;
}

.green-tea {
  background-color: #E0F6E5;
}

.gray-100 {
  background-color: $color-gray-100;
}

.light-pink {
  background-color: #FFE6E0;
}

.light-purple {
  background: #F7DFFF;
}

.light-green {
  background: rgba(0, 152, 102, 0.05);
}
