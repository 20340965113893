@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-reports-page {
  .report-links {
    padding: 16px;
  }
}

.root-report-link {
  margin-bottom: 4px;
  font-size: 16px;
}
