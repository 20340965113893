@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-text-input {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #D5DEDF;
  margin: 0;
  background-color: white;
  border-radius: 4px;
  transition: border 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s;

  &.text-input-invalid {
    border-color: #FA4547 !important;
  }

  &.corners-right {
    border-radius: 4px 0 0 4px;
  }

  &.corners-left {
    border-radius: 0 4px 4px 0;
  }

  &.disabled {
    background-color: #F7F7F7;
  }

  .text-input-input {
    width: 100%;
    height: 100%;
    padding-right: 6px;
    padding-left: 6px;
    border: none;
    border-radius: 4px;
    color: #19282B;
    font-size: 13px;
    font-weight: normal;
    line-height: 150%;

    &:focus {
      outline: none !important;
    }

    &::placeholder {
      color: $color-blue-grey;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
    }
  }

  .input-pointer {
    cursor: pointer;
  }

  .text-input-icon {
    position: absolute;
    top: 6px;
    right: 5px;
    width: 16px;
    height: 16px;
    background-color: $color-white;
    cursor: pointer;
    pointer-events: none;
  }

  .text-input-icon-clear {
    position: absolute;
    top: 6px;
    right: 5px;
    width: 16px;
    height: 16px;
    background-color: $color-white;
    cursor: pointer;
    fill: #7799A1;
  }

  &:focus-within {
    border: 1px solid $color-primary;
    box-shadow: 0 0 3px #D5DEDF;
    outline: none !important;
  }

  &:hover {
    border: 1px solid $color-primary;
  }

  .grey-background {
    background-color: $color-gray-100;
  }
}

.text-input-whitch-icon {
  .text-input-input {
    padding-right: 30px;
  }

  input::placeholder {
    color: #BFBFBF !important;
    font-family: Rubik, sans-serif !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 130% !important;
  }
}
