@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.card-header-hidden {
  .card-header {
    display: none !important;
  }

  .card-body {
    border-top: 0 !important;
  }
}

.root-card-overflow {
  height: calc(100vh - 180px);

  .card-body {
    overflow-y: auto;
  }
}

.root-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-width: 240px;
  border: 1px solid #D5DEDF;
  background: $color-white;
  border-radius: 8px;

  .card-header {
    @include flex(row, space-between, center);

    padding: 17px 18px;
    margin: 0;
    transition: background-color 200ms;

    &.header-empty:not(.header-error) {
      background-color: #F4F6F9;
    }

    &.header-error {
      background-color: $color-orange-100;

      .card-header-title {
        color: $color-white;
      }
    }

    .card-header-title {
      @include flex(row, flex-start, center);

      width: 100%;
      color: $color-blue-gray-40;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      transition: color 200ms;

      .card-header-title-title {
        width: 100%;
        max-width: max-content;
        white-space: nowrap;
      }

      .card-header-title-data {
        @include flex(row, flex-end, center);

        width: 100%;

        .card-header-title-data-item {
          margin: 0 4px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .card-header-title-data-row {
        @include flex(column, center, flex-start);

        width: 100%;

        .card-header-title-data-row-row {
          @include flex(row, space-between, flex-start);

          width: 100%;

          .card-header-title-data-row-row-cell:first-of-type {
            width: 100%;
          }
        }
      }
    }
  }

  .card-body {
    border-top: 1px solid #D5DEDF;

    &.card-body-empty {
      border-top: 0;
    }

    .card-column {
      @include flex(row, flex-start, flex-start);

      min-height: 45px;
      padding: 16px 17px;
      margin: 0;
      color: $color-blue-gray-40;
      cursor: pointer;

      a {
        max-width: 80%;
        padding-top: 2px;
        color: $color-blue-grey;
        font-size: 14px;
        line-height: 150%;
        text-decoration: underline;
      }

      .card-column-title {
        width: 40%;
        font-size: 12px;
        font-weight: 500;
        line-height: 160%;
      }

      .card-column-data {
        width: 60%;
        font-size: 14px;
        font-weight: normal;
        line-height: 140%;
        text-overflow: ellipsis;
      }

      .card-column-data-row {
        @include flex(row, flex-start, center);

        font-size: 14px;
        font-weight: normal;
        line-height: 140%;
        text-overflow: ellipsis;
      }

      .card-column-action {
        max-width: 80%;
        padding-top: 2px;
        color: $color-green-80;
        cursor: pointer;
        font-size: 13px;
        font-weight: normal;

        &:hover {
          text-decoration: underline;
        }
      }

      &:nth-child(odd) {
        background-color: #FAFBFA;
      }
    }
  }

  .card-bottom {
    @include flex(row, flex-end, flex-start);

    padding: 16px;
    border-top: 1px solid #D5DEDF;
    margin: 0;

    .card-bottom-item {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .card-row {
    @include flex(row, flex-start, flex-start);

    padding: 16px 0;
    border-bottom: 1px solid #E3F1F4;
    margin: 0 16px;

    &:last-child {
      border-bottom: none;
    }

    &-cols-1 {
      .card-column {
        width: 100%;
      }
    }

    &-cols-2 {
      .card-column {
        width: 50%;
      }
    }

    &-cols-3 {
      .card-column {
        width: 33%;
      }
    }
  }
}
