@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";
@import "@src/styles/utilities/index.scss";

.root-empty-state {
  @include flex(column, flex-start, center);

  width: 100%;
  height: 100%;

  .empty-state-container {
    @include flex(column, center, center);

    margin-top: 80px;
    color: $color-blue-gray-40;
    text-align: center;

    .empty-state-icon {
      margin-bottom: 14px;
    }

    .empty-state-caption {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 500;
    }

    .empty-state-description {
      max-width: 240px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
