@import "@src/styles/variables.scss";
@import "@src/styles/mixins.scss";

.root-reg-conditions-list {
  width: 450px;
  min-width: 450px;
  height: calc(100vh - 122px);
  border: 1px solid #D5DEDF;
  margin-right: 16px;
  background-color: white;
  border-radius: 8px;

  &::-webkit-scrollbar {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }

  .button-board {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px 12px;
  }
}

.root-condition-list-entry {
  @include flex(row, flex-start, center);

  height: 45px;
  padding-right: 8px;
  padding-left: 16px;
  border-bottom: 1px solid #ECF2F0;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &:first-child {
    border-top-left-radius: 8px;
  }

  &.selected {
    background-color: #ECF2F0;
  }

  &.condition-new {
    font-style: italic;
  }
}
